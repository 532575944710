//###############################################
//########## INSIDE SPOOKY ROOM MIRROR ##########
//###############################################

/////////////////////////////////////
////// NODE & NPM DEPENDENCIES //////
/////////////////////////////////////
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { io } from "socket.io-client";

///////////////////////////////
////// STYLED COMPONENTS //////
///////////////////////////////
import {
  MemeRoomMirrorFrame,
  MemeRoomMirrorBG
} from '../../../styledComponents/MemeRoom.js';

////////////////////////
////// COMPONENTS //////
////////////////////////
import MirrorMemes from './MirrorMemes.js';

////////////////////////////
////// THIS COMPONENT //////
////////////////////////////
export default function InsideMirror() {

  const accessKey = process.env.REACT_APP_ACCESS_KEY;
  const webAccessKey = process.env.REACT_APP_WEB_ACCESS_KEY;

  const [sceneData, setSceneData] = useState({
    loading: true,    
    meme_review: { active : false }
  });

  useEffect(() => {
    const fetchData = async () => {
      const url = process.env.REACT_APP_STUDIO_API_URL + "/bodach/get-current-scene?t=" + webAccessKey;
      try {
        const response = await axios.get(url);

        if (typeof response === "object") {
          if (response.data.qry === 1) {
            setSceneData(response.data.res.currentData);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_API_URL, {
      query: { accessKey },
      transports: ['websocket'],
      upgrade: false
    });

    socket.on("bodachSceneUpdate", data => {
      setSceneData(data);
    });

    return () => socket.disconnect();
  }, []);
 
  const now = new Date(); 
  now.setHours(now.getHours() + 12);
  const yyyy = now.getFullYear();
  const mm = String(now.getMonth() + 1).padStart(2, '0'); 
  const dd = String(now.getDate()).padStart(2, '0');  
  const hours = String(now.getHours()).padStart(2, '0'); 
  const minutes = String(now.getMinutes()).padStart(2, '0'); 
  const timeNow = `${yyyy}-${mm}-${dd} ${hours}:${minutes}`; 

  const videoRef = useRef(null);
  const memeRoomMirrorBGRef = useRef();

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch(e => { });
    }
  }, []);

  const [currentSlide, setCurrentSlide] = useState({
    slideType: "none",
    slideUrl: "",
    time: Date.now()
  })

  const checkFileStatus = async (url) => {
    try {
      const response = await axios.get(url);
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {

    const fetchData = async () => {

      if (sceneData.current_slide !== undefined) {
        if (sceneData.current_slide === 0) {

          setCurrentSlide({
            slideType: "none",
            slideUrl: "",
            time: Date.now()
          })

        } else {

          let fileFound = false;

          //check for jpg
          const responseJPG = await checkFileStatus(process.env.REACT_APP_DUMP_URL + "/currentMemes/" + sceneData.current_slide + ".jpg?t=" + timeNow);
          if (responseJPG) {

            await checkFileStatus(process.env.REACT_APP_STUDIO_API_URL + "/bodach/change-audio/1?t=" + webAccessKey);

            fileFound = true;
            setCurrentSlide({
              slideType: "image",
              slideUrl: process.env.REACT_APP_DUMP_URL + "/currentMemes/" + sceneData.current_slide + ".jpg?t=" + timeNow,
              time: Date.now()
            })
          }

          //check for jpg
          const responseJPG2 = await checkFileStatus(process.env.REACT_APP_DUMP_URL + "/currentSmegSlides/" + sceneData.current_slide + ".JPG?t=" + timeNow);
          if (responseJPG2) {
  
            await checkFileStatus(process.env.REACT_APP_STUDIO_API_URL + "/bodach/change-audio/1?t=" + webAccessKey);
  
            fileFound = true;
            setCurrentSlide({
              slideType: "image",
              slideUrl: process.env.REACT_APP_DUMP_URL + "/currentSmegSlides/" + sceneData.current_slide + ".JPG?t=" + timeNow
            })
          }

          //check for mp4
          if (!fileFound) {
            const responseMP4 = await checkFileStatus(process.env.REACT_APP_DUMP_URL + "/currentMemes/" + sceneData.current_slide + ".mp4?t=" + timeNow); 

            if (responseMP4) {

              await checkFileStatus(process.env.REACT_APP_STUDIO_API_URL + "/bodach/change-audio/0?t=" + webAccessKey);

              fileFound = true;
              setCurrentSlide({
                slideType: "video",
                slideUrl: process.env.REACT_APP_DUMP_URL + "/currentMemes/" + sceneData.current_slide + ".mp4?t=" + timeNow,
                time: Date.now()
              })
            }
          }

          //check for mp4
          if (!fileFound) {
            const responseMP4b = await checkFileStatus(process.env.REACT_APP_DUMP_URL + "/gymSlides/" + sceneData.current_slide + ".MP4?t=" + timeNow);
            if (responseMP4b) {
  
              await checkFileStatus(process.env.REACT_APP_STUDIO_API_URL + "/bodach/change-audio/0?t=" + webAccessKey);
  
              fileFound = true;
              setCurrentSlide({
                slideType: "video",
                slideUrl: process.env.REACT_APP_DUMP_URL + "/gymSlides/" + sceneData.current_slide + ".MP4?t=" + timeNow
              })
            }
          }

          //check for png
          if (!fileFound) {
            const responsePNG = await checkFileStatus(process.env.REACT_APP_DUMP_URL + "/currentMemes/" + sceneData.current_slide + ".png?t=" + timeNow);
            if (responsePNG) {

              await checkFileStatus(process.env.REACT_APP_STUDIO_API_URL + "/bodach/change-audio/1?t=" + webAccessKey);

              fileFound = true;
              setCurrentSlide({
                slideType: "image",
                slideUrl: process.env.REACT_APP_DUMP_URL + "/currentMemes/" + sceneData.current_slide + ".png?t=" + timeNow,
                time: Date.now()
              })
            }
          }

          //check for png
          if (!fileFound) {
            const responsePNGb = await checkFileStatus(process.env.REACT_APP_DUMP_URL + "/gymSlides/" + sceneData.current_slide + ".PNG?t=" + timeNow);
            if (responsePNGb) {
  
              await checkFileStatus(process.env.REACT_APP_STUDIO_API_URL + "/bodach/change-audio/1?t=" + webAccessKey);
  
              fileFound = true;
              setCurrentSlide({
                slideType: "image",
                slideUrl: process.env.REACT_APP_DUMP_URL + "/gymSlides/" + sceneData.current_slide + ".PNG?t=" + timeNow
              })
            }
          }

          //check for webp
          if (!fileFound) {
            const responseWEBP = await checkFileStatus(process.env.REACT_APP_DUMP_URL + "/currentMemes/" + sceneData.current_slide + ".webp?t=" + timeNow);
            if (responseWEBP) {

              await checkFileStatus(process.env.REACT_APP_STUDIO_API_URL + "/bodach/change-audio/1?t=" + webAccessKey);

              fileFound = true;
              setCurrentSlide({
                slideType: "image",
                slideUrl: process.env.REACT_APP_DUMP_URL + "/currentMemes/" + sceneData.current_slide + ".webp?t=" + timeNow,
                time: Date.now()
              })
            }
          }

          //check for webp
          if (!fileFound) {
            const responseWEBPb = await checkFileStatus(process.env.REACT_APP_DUMP_URL + "/currentSmegSlides/" + sceneData.current_slide + ".WEBP?t=" + timeNow);
            if (responseWEBPb) {
  
              await checkFileStatus(process.env.REACT_APP_STUDIO_API_URL + "/bodach/change-audio/1?t=" + webAccessKey);
  
              fileFound = true;
              setCurrentSlide({
                slideType: "image",
                slideUrl: process.env.REACT_APP_DUMP_URL + "/currentSmegSlides/" + sceneData.current_slide + ".WEBP?t=" + timeNow
              })
            }
          }

          //nothiong
          if (!fileFound) {

            await checkFileStatus(process.env.REACT_APP_STUDIO_API_URL + "/bodach/change-audio/1?t=" + webAccessKey);

            await axios.get(process.env.REACT_APP_STUDIO_API_URL + "/bodach/change-slide/start?t=" + webAccessKey);
            setCurrentSlide({
              slideType: "none",
              slideUrl: "",
              time: Date.now()
            })
          }

        }
      }
    };
    if (!sceneData.loading) {
      if (sceneData.meme_review.active) {
        fetchData();
      }
    }
  }, [sceneData.current_slide, sceneData.meme_review.active]);

  if (sceneData.loading) { return null; }
  return (
    <>
      <MemeRoomMirrorFrame id="meme_room_MemeRoomMirrorFrameRef" />
      <MemeRoomMirrorBG
        ref={memeRoomMirrorBGRef}
        id="meme_room_MemeRoomMirrorBG"
      >

        {sceneData.meme_review.active ? (
          <>
            <MirrorMemes
              currentSlide={currentSlide}
              paused={sceneData.slide_pause}
            />
            <video
              ref={videoRef}
              className="vortex"
              autoPlay loop muted
              onCanPlayThrough={() => videoRef.current && videoRef.current.play()}
            >
              <source src={process.env.REACT_APP_PUBLIC_URL + "/images/meme-room/vortex.mp4"} type="video/mp4" />
            </video>
          </>
        ) : null}

      </MemeRoomMirrorBG>
    </>
  )

}