//##########################################
//######## PROJECTOR ROOM OVERVIEW  ########
//##########################################

///////////////////////////////
////// STYLED COMPONENTS //////
///////////////////////////////
import {
  Paragraph,
  OverviewContainer,
} from '../../styledComponents/Overview.js';

////////////////////////////
////// THIS COMPONENT //////
////////////////////////////
export default function ProjectorRoom() {

  return (
    <OverviewContainer>
      <Paragraph>
        Hello humans, welcome to the stream, my podcast, my desperate need for attention, my show. My name is Bodach, and I was exiled, here, to earth, FRAMED i was, for crimes I didn’t commit, but that’s a story for another day.
      </Paragraph>
      <Paragraph>
        This is my thing, I ramble, I chit chat, I fight technical glitches. I've probably been neuralyzed by the Men in Black multiple times, considering there's not a rabbit hole not worth my time. I wear my tin foil hat with pride, never shy of speaking my mind. 
      </Paragraph>   
      <Paragraph>
        Join me, help me, enable my bad choices, maybe then, I will finally completely that EFFFF ing computer game.
      </Paragraph>
      <Paragraph>
        I live in a castle, it's not mine. I don't know who owns it, never met them. but I’ve been here for years, no one checks. My man cave is here, I have my own gym, and there’s this weird mirror down in the basement that shows me memes.
      </Paragraph>
      <Paragraph>
        I host my own talk show, where I invite guests on for a chinwag, I also host the quiz show, Silly Sausages, and sometimes I try a bit of open mic down at the local comedy club.
      </Paragraph>
      <Paragraph>
        I also have the honor of being a regular guest speaker at the institute for Science Memes Entertainment and Gaming. You will find my previous SMEG talks on all my channels. 
      </Paragraph>
      <Paragraph>
        I'm not alone, my mate Jasper is behind the scenes. He hides here, he's from my world, but the less you know about him, the better. Jasper is my producer, my editor, my security, my driver, my cook, my secret santa, and my dealer.
      </Paragraph>
      <Paragraph>
        I stream on Twitch, Youtube, Kick, X, Facebook, Rumble, Trovo and D-live, and you can also follow me on TikTok, Instagram, BitChute and Odysee. Head to bodach.show, that's my website, built it myself, that'll give you the links to all my stuff. I would really appreciate it if you subbed and followed on all the platforms you have accounts for. It would help me out so much.
      </Paragraph>
      <Paragraph>
        Feel free to jump in the chat, and also feel free to clip and share.
      </Paragraph>
      <Paragraph>
        Enjoy and lets crack on.
      </Paragraph>
    </OverviewContainer>
  )

}