//####################################
//########## GYM BACKGROUND ##########
//####################################

/////////////////////////////////////
////// NODE & NPM DEPENDENCIES //////
/////////////////////////////////////
import React from 'react';

///////////////////////////////
////// STYLED COMPONENTS //////
///////////////////////////////
import {
  TheGymFrame,
  GymWall,
  GymFloor,
  GymTV
} from '../../styledComponents/TheGym.js';

////////////////////////
////// COMPONENTS //////
////////////////////////
import GymTVSlides from './gymTV/GymTVSlides.js'

////////////////////////////
////// THIS COMPONENT //////
////////////////////////////
export default function GymBackground() { 

  return (
    <TheGymFrame>

      <GymWall
        id='gym_gymWallRef' 
      />

      <GymFloor
        id='gym_gymFloorRef' 
      />

      <GymTV
        id='gym_gymTVRef' 
      > 
          <GymTVSlides /> 
      </GymTV>

    </TheGymFrame>
  )
}