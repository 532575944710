//####################################################
//############### NEWS ROOM FOREGROUND ###############
//####################################################

/////////////////////////////////////
////// NODE & NPM DEPENDENCIES //////
/////////////////////////////////////
import React from 'react';

///////////////////////////////
////// STYLED COMPONENTS //////
///////////////////////////////
import {
  TheNewsRoomFrame,
  NewsDesk
} from '../../styledComponents/NewsRoom.js';

////////////////////////////
////// THIS COMPONENT //////
////////////////////////////
export default function NewsRoomForeground() {
 
  return (
    <TheNewsRoomFrame>

      <NewsDesk
        id='news_room_deskRef' 
      />

    </TheNewsRoomFrame>
  )
}