//###############################
//########## MEME ROOM ##########
//###############################
import styled, { css, keyframes } from 'styled-components';

/////////////////////////////////
////// STYLED VARS IMPORTS //////
/////////////////////////////////
import { easing } from './vars/scEasing';

//#########################
//### STYLED COMPONENTS ###
//#########################

////////////////////////////
///////// CANVASES /////////
////////////////////////////
export const TheMemeRoomFrame = styled.div`
  display: block;
  width: 3840px;
  height: 2160px;
  position: absolute;
  top:0;
  left:0;
  z-index: 7;  
`;


////////////////////////////
//////// BACKGROUND ////////
////////////////////////////
export const MemeRoomFloor = styled.div`
  display: none;
  width: 3840px;
  height: 620px;
  background-size: cover;
  position: absolute;
  bottom:0;
  left:0;
  z-index: 2; 
  background-image: url('images/meme-room/floor.png');
  opacity:0; 
  transform: translateY(50px)scale(1.05);

  &.active{
    transition: all 500ms ${easing.easeOutCubic};
    transition-delay:100ms;
    will-change: opacity, transform;
    opacity:1; 
    transform: translateY(0px)scale(1);
  } 
`;

export const MemeRoomWall = styled.div`
  display: none;
  width: 3840px;
  height: 1628px;
  background-size: cover;
  position: absolute;
  top:0;
  left:0; 
  z-index: 1; 
  background-image: url('images/meme-room/wall.png');
  opacity:0; 
  transform: translateY(-50px);

  &.active{
    transition: all 500ms ${easing.easeOutCubic};
    transition-delay:100ms;
    will-change: opacity, transform;
    opacity:1;
    transform: translateY(0px);
  }  
`;

export const MemeRoomMirror = styled.div`
  display: none;
  width: 1095px;
  height: 1316px; 
  position: absolute;
  top:0;
  left:0;
  transform: translateX(995px)translateY(120px)scale(1.1); 
  z-index: 3;
  opacity:0;

  &.active{ 
      transition: all 1000ms ${easing.easeOutCubic};
      transition-delay: 500ms;
      will-change: opacity, transform;
      opacity:1;
      transform: translateX(995px)translateY(120px)scale(1);       
  } 
`;

export const MemeRoomMirrorFrame = styled.div`
  display: block;
  width: 1095px;
  height: 1316px;
  background-size: cover;
  position: absolute;
  top:0;
  left:0;
  transition: all 500ms ${easing.easeOutCubic};
  z-index: 20; 
  background-image: url('images/meme-room/mirror.png');  
`;

export const MemeRoomMirrorBG = styled.div`
  display: block;
  width: 540px;
  height: 670px;
  background-color: #000000;
  overflow:hidden;
  background-size: cover;
  position: absolute;
  top:0;
  left:0;
  transform: translateX(280px)translateY(340px); 
  z-index: 1; 
  
  &.accepted{
    .vortex{
      filter: grayscale(100%);
      transition: all 2000ms ${easing.easeOutCubic};
    }
    .mirrorMemeInsetContainer{
      box-shadow: 0px 0px 40px 30px rgba(80, 207, 0, 1)!important;
      transition: all 2000ms ${easing.easeOutCubic};
    }
    .mirrorMemeImage{
      box-shadow: 0px 0px 160px 120px rgba(80, 207, 0, 1)!important;
      transition: all 2000ms ${easing.easeOutCubic};
    }
    .mirrorMemeVideo{
      box-shadow: 0px 0px 160px 120px rgba(80, 207, 0, 1)!important;
      transition: all 2000ms ${easing.easeOutCubic};
    }
    .mirrorMemeInsetShadow{
      box-shadow: inset  0px 0px 30px 30px rgba(80, 207, 0, 1)!important;
      transition: all 2000ms ${easing.easeOutCubic};
    }
  }

  &.rejected{
    .vortex{
      filter: grayscale(100%);
      transition: all 2000ms ${easing.easeOutCubic};
    }
    .mirrorMemeInsetContainer{
      box-shadow: 0px 0px 40px 30px rgba(206, 19, 13, 1)!important;
      transition: all 2000ms ${easing.easeOutCubic};
    }
    .mirrorMemeImage{
      box-shadow: 0px 0px 160px 120px rgba(206, 19, 13, 1)!important;
      transition: all 2000ms ${easing.easeOutCubic};
    }
    .mirrorMemeVideo{
      box-shadow: 0px 0px 160px 120px rgba(206, 19, 13, 1)!important;
      transition: all 2000ms ${easing.easeOutCubic};
    }
    .mirrorMemeInsetShadow{
      box-shadow: inset  0px 0px 30px 30px rgba(206, 19, 13, 1)!important;
      transition: all 2000ms ${easing.easeOutCubic};
    }
  }

  .vortex{
    display: block;
    position: absolute;
    top:0;
    left:0;
    z-index: 50;
    object-fit: cover;
    width:670px;
    height: 540px;
    transform: translateX(-70px)translateY(70px)scale(1)rotate(90deg); 
    transition: all 2000ms ${easing.easeOutCubic};
  }
`;

const floatAni = keyframes`
  0% {
    transform: scale(1)translateX(0px)translateY(-2px)rotate(0.5deg);
  }
  25% {
    transform: scale(0.99)translateX(-2px)translateY(0px)rotate(-1deg);
  }
  50% {
    transform: scale(1)translateX(2px)translateY(-2px)rotate(1deg);
  }
  75% {
    transform: scale(0.99)translateX(-2px)translateY(0px)rotate(-1deg);
  }
  100% {
    transform: scale(1)translateX(0px)translateY(-2px)rotate(0.5deg);
  }
`;

export const MirrorMeme = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 540px;
  height: 670px; 
  overflow:visible; 
  position: absolute;
  top:0;
  left:0;
  transform: translateX(-30px)translateY(20px)scale(0);
  transition: all 500ms ${easing.easeOutCubic};
  z-index: 60;
  opacity: 0; 

  .mirrorMemeInsetContainer{
    display: flex;
    height: auto;
    width:auto;
    position: relative; 
    border-radius: 50px; 
    box-shadow: 0px 0px 20px 15px rgba(1, 93, 192, 1); 
    transition: all 500ms ${easing.easeOutCubic};
    animation: ${floatAni} 20s ease infinite; 
  }

  .mirrorMemeImage{
    display: block;
    width:100%;
    height:auto;
    max-height: 670px;
    overflow: hidden;
    border-radius: 50px; 
    border:2px solid  rgba(1, 93, 192, 1);
    box-shadow: 0px 0px 80px 60px rgba(1, 93, 192, 1);
    transition: all 500ms ${easing.easeOutCubic};
  }

  .mirrorMemeVideo{
    display: block;
    width:auto;
    height:670px;
    overflow: hidden;
    border-radius: 50px; 
    border:2px solid rgba(1, 93, 192, 1);
    box-shadow: 0px 0px 80px 60px rgba(1, 93, 192, 1);
    transition: all 500ms ${easing.easeOutCubic}; 
  }

  .mirrorMemeInsetShadow{
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    border-radius: 50px;  
    box-shadow: inset  0px 0px 15px 15px rgba(1, 93, 192, 1);
    transition: all 500ms ${easing.easeOutCubic};
  }

  &.active{
    transform: translateX(0)translateY(0)scale(0.8);
    transition: all 500ms ${easing.easeOutCubic}; 
    opacity: 0.95;
    will-change: opacity, transition;
  }  

  ${props =>
    props.$active &&
    css`
      transform: translateX(0)translateY(0)scale(0.8);
      transition: all 500ms ${easing.easeOutCubic}; 
      opacity: 0.95;
      will-change: opacity, transition;
  `};
`;

////////////////////////////
//////// FOREGROUND ////////
////////////////////////////
export const MemeRoomLeftChair = styled.div`
  display: none;
  width: 1054px;
  height: 1222px;
  background-size: cover;
  position: absolute;
  bottom:0;
  left:0; 
  transform: translateX(-1064px)translateY(-25px); 
  z-index: 5; 
  background-image: url('images/meme-room/left-chair.png'); 

  &.active{ 
    transition: all 500ms ${easing.easeOutCubic};
    transition-delay: 500ms;
    will-change:  transform; 
    transform: translateX(0px)translateY(-25px); 
  }   
`;

export const MemeRoomRightChair = styled.div`
  display: none;
  width: 1010px;
  height: 1222px;
  background-size: cover;
  position: absolute;
  bottom:0;
  right:0; 
  transform: translateX(3850px)translateY(-170px);
  z-index: 5; 
  background-image: url('images/meme-room/right-chair.png'); 

  &.active{ 
    transition: all 500ms ${easing.easeOutCubic};
    transition-delay: 600ms;
    will-change:opacity;  
    transform: translateX(0px)translateY(-170px);
  }   
`;
