//#####################################################
//############### HANDLE OBS BACKGROUND ###############
//#####################################################

/////////////////////////////////////
////// NODE & NPM DEPENDENCIES //////
/////////////////////////////////////
import React, { useEffect } from 'react';
import axios from 'axios';
import { io } from "socket.io-client";

////////////////////////
////// COMPONENTS //////
////////////////////////
import NestMidGround from '../components/midgrounds/NestMidGround.js';

/////////////////////////////
////// UTILITY IMPORTS //////
/////////////////////////////
import doMidGroundUpdate from '../utils/doMidGroundUpdate.js';

///////////////////////////////
////// STYLED COMPONENTS //////
///////////////////////////////
import { Canvas } from '../styledApp';

//////////////////////////
////// THIS HANDLER //////
//////////////////////////
export default function HandleOBSMidGround(props) {

  console.log('HandleOBSBackground');

  const accessKey = process.env.REACT_APP_ACCESS_KEY;
  const webAccessKey = process.env.REACT_APP_WEB_ACCESS_KEY;

  useEffect(() => {
    const fetchData = async () => {
      const url = process.env.REACT_APP_STUDIO_API_URL + "/bodach/get-current-scene?t=" + webAccessKey;
      try {
        const response = await axios.get(url);

        if (typeof response === "object") {
          if (response.data.qry === 1) {
            doMidGroundUpdate(response.data.res.currentData);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_API_URL, {
      query: { accessKey },
      transports: ['websocket'],
      upgrade: false
    });

    socket.on("bodachSceneUpdate", data => { 
      doMidGroundUpdate(data);
    });

    return () => socket.disconnect();
  }, []);

  return (
    <Canvas>
 
      <NestMidGround />
 
    </Canvas>
  )
}