//#####################################
//############## THE GYM ##############
//#####################################
import styled, { css } from 'styled-components';

/////////////////////////////////
////// STYLED VARS IMPORTS //////
/////////////////////////////////
import { easing } from './vars/scEasing';

//#########################
//### STYLED COMPONENTS ###
//#########################

////////////////////////////
///////// CANVASES /////////
////////////////////////////
export const TheGymFrame = styled.div`
  display: block;
  width: 3840px;
  height: 2160px;
  position: absolute;
  top:0;
  left:0;
  z-index: 2;  
`; 

////////////////////////////
//////// BACKGROUND ////////
////////////////////////////
export const GymWall = styled.div`
  display: none;
  width: 3840px;
  height: 1678px;
  background-image: url('images/the-gym/wall.png');
  background-size: cover;
  position: absolute;
  top:0;
  left:0;
  z-index: 40; 
  transform: translateX(0px)translateY(-100px); 
  opacity: 0;

  &.active{
    transform: translateX(0px)translateY(0px);
    transition: all 500ms ${easing.easeOutCubic};
    transition-delay: 350ms;
    will-change: transform, opacity;
    opacity: 1;
  }  
`;

export const GymFloor = styled.div`
  display: none;
  width: 3840px;
  height: 693px;
  background-image: url('images/the-gym/floor.png');
  background-size: cover;
  position: absolute;
  bottom:0;
  left:0;
  z-index: 40; 
  transform: translateX(0px)translateY(100px); 
  opacity: 0;

  &.active{
    transform: translateX(0px)translateY(0px);
    transition: all 500ms ${easing.easeOutCubic};
    transition-delay: 100ms;
    will-change: transform, opacity;
    opacity: 1;
  } 
`;

export const GymTV = styled.div`
  display: none;
  width: 1160px;
  height: 671px;
  background-image: url('images/the-gym/gym-tv.png');
  background-size: cover;
  position: absolute;
  top:0;
  left:0;
  z-index: 40; 
  transform: translateX(1500px)translateY(450px)scale(0); 

  &.active{
    transform: translateX(1500px)translateY(450px)scale(1);
    transition: all 500ms ${easing.easeOutBack}; 
    transition-delay: 650ms;
    will-change: transform;
  }  
`;

export const GymTVSlidesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1070px;
  height: 587px;
  position: absolute;
  background-color: rgba(0,0,0,1);
  top:0;
  left:0;
  z-index: 40; 
  transform: translateX(45px)translateY(20px); 
`;

export const GymMeme = styled.div`
  display: flex;
  align-items: left;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top:0;
  left:0;
  z-index: 1; 
  overflow: hidden;
  opacity: 0; 
  
  .gymSlideImage{
    display: block;
    width:100%;
    height: 100%;
    position: absolute;
    left: 0;
    top:0;
    object-fit: contain; 
    z-index: 5;
  }

  .gymSlideImageBG{
    display: block;
    width:100%;
    height: 100%;
    position: absolute;
    left: 0;
    top:0;
    object-fit: cover; 
    z-index: 1;
    filter:brightness(25%)blur(10px);
  }

  &.active{
    transition: all 500ms ${easing.easeOutCubic};
    opacity: 1;
    will-change: opacity; 
    transition-delay:500ms;
  }
  
  ${props =>
    props.$active &&
    css` 
       transition: all 500ms ${easing.easeOutCubic};
       opacity: 1;
       will-change: opacity; 
       transition-delay:500ms;
    `}; 
`;



////////////////////////////
//////// FOREGROUND ////////
////////////////////////////
export const GymLeftKit = styled.div`
  display: none;
  width: 1536px;
  height: 1750px;
  background-image: url('images/the-gym/left-side-kit.png');
  background-size: cover;
  position: absolute;
  bottom:0;
  left:0;
  z-index: 40; 
  transform: translateX(-1600px)translateY(0px);  

  &.active{
    transform: translateX(0px)translateY(0px);
    transition: all 500ms ${easing.easeOutCubic};
    transition-delay: 850ms;
    will-change: transform;
  } 
`;

export const GymRightKit = styled.div`
  display: none;
  width: 1157px;
  height: 1941px;
  background-image: url('images/the-gym/right-side-kit.png');
  background-size: cover;
  position: absolute;
  bottom:0;
  right:0;
  z-index: 40; 
  transform: translateX(1200px)translateY(0px); 

  &.active{
    transform: translateX(0px)translateY(0px);
    transition: all 500ms ${easing.easeOutCubic};
    transition-delay: 1050ms;
    will-change: transform; 
  } 
`;