//###############################################
//############### NEST FOREGROUND ###############
//###############################################
import React from 'react';
import { TheNestFrame, NestDesk, NestTV, NestPlant5 } from '../../styledComponents/TheNest.js';

export default function NestForeground() {

  return (
    <TheNestFrame>

      <NestPlant5
        id='nest-plant5Ref' 
      />

      <NestDesk
        id='nest-deskRef' 
      />

      <NestTV
        id='nest-tvRef' 
      />

    </TheNestFrame>
  )
}