//######################################
//######## DO BACKGROUND UPDATE  #######
//######################################

///////////////////////////////
////// IN-LINE FUNCTIONS //////
///////////////////////////////
async function addActive(id) {
  const element = document.getElementById(id);

  if (element) {
    element.classList.add('prep');
  }

  setTimeout(() => {
    if (element) {
      element.classList.add('active');
    }
  }, 75);
}

async function removeActive(id) {
  const element = document.getElementById(id);
  if (element) {
    element.classList.remove('active');
    element.classList.remove('prep');
  }
}

///////////////////////////
////// THIS FUNCTION //////
///////////////////////////
const doBackgroundUpdate = async (data) => {

  // PROJECTOR ROOM
  if (data.projector_room.active) {
    addActive('pr-projectorRoomBGRef');
    addActive('pr-tableRef');
  } else {
    removeActive('pr-projectorRoomBGRef');
    removeActive('pr-tableRef');
  }

  // NEST ELEMENTS
  if (data.nest.active) {
    addActive('nest-tvBgRef');
    addActive('nest-plant1Ref');
    addActive('nest-plant2Ref');
    addActive('nest-plant3Ref');
    addActive('nest-plant4Ref');
    addActive('nest-photo1Ref');
    addActive('nest-photo2Ref');
    addActive('nest-photo3Ref');
    addActive('nest-photo4Ref');
    addActive('nest-photo5Ref');
    addActive('nest-sofaRef');
    addActive('nest-bg3');
    addActive('nest-bg2');
    addActive('nest-bg1');
  } else {
    removeActive('nest-tvBgRef');
    removeActive('nest-plant1Ref');
    removeActive('nest-plant2Ref');
    removeActive('nest-plant3Ref');
    removeActive('nest-plant4Ref');
    removeActive('nest-photo1Ref');
    removeActive('nest-photo2Ref');
    removeActive('nest-photo3Ref');
    removeActive('nest-photo4Ref');
    removeActive('nest-photo5Ref');
    removeActive('nest-sofaRef');
    removeActive('nest-bg3');
    removeActive('nest-bg2');
    removeActive('nest-bg1');
  }

  // MEME ROOM
  if (data.meme_review.active) {
    addActive('meme_room_memeRoomFloorRef');
    addActive('meme_room_memeRoomWallRef');
    addActive('meme_room_memeRoomMirrorRef');
  } else {
    removeActive('meme_room_memeRoomFloorRef');
    removeActive('meme_room_memeRoomWallRef');
    removeActive('meme_room_memeRoomMirrorRef');
  }

  // COMEDY CLUB
  if (data.comedy_club.active) {
    addActive('comedy_club_leftCurtainRef'); 
    addActive('comedy_club_rightCurtainRef'); 
    addActive('comedy_club_wallRef'); 
    addActive('comedy_club_signRef'); 
    addActive('comedy_club_floorRef'); 
  } else {
    removeActive('comedy_club_leftCurtainRef');  
    removeActive('comedy_club_rightCurtainRef');  
    removeActive('comedy_club_wallRef');  
    removeActive('comedy_club_signRef');  
    removeActive('comedy_club_floorRef'); 
  }

  // NEWS ROOM
  if (data.news_room.active) {
    addActive('news_room_NewsRoomBackgroundVideo'); 
  } else {
    removeActive('news_room_NewsRoomBackgroundVideo'); 
  }

  // SMEG TALKS
  if (data.smeg_talk.active) {
    addActive('smeg_talk_smegCurtainRef'); 
    addActive('smeg_talk_smegFloorRef'); 
    addActive('smeg_talk_smegSignRef'); 
    addActive('smeg_talk_smegRugRef'); 
  } else {
    removeActive('smeg_talk_smegCurtainRef'); 
    removeActive('smeg_talk_smegFloorRef'); 
    removeActive('smeg_talk_smegSignRef'); 
    removeActive('smeg_talk_smegRugRef'); 
  }

  // GYM
  if (data.gym.active) {
    addActive('gym_gymWallRef'); 
    addActive('gym_gymFloorRef');
    addActive('gym_gymTVRef');
  } else {
    removeActive('gym_gymWallRef');  
    removeActive('gym_gymFloorRef'); 
    removeActive('gym_gymTVRef');  
  }

  // CHAT SHOW
  if (data.chat_show.active) {
    addActive('chat_show_bgRef');  
    addActive('chat_show_floorRef');  
    addActive('chat_show_sofaBgRef');  
  } else {
    removeActive('chat_show_bgRef'); 
    removeActive('chat_show_floorRef'); 
    removeActive('chat_show_sofaBgRef');   
  }

  // Quiz SHOW
  if (data.quiz_show.active) {
    addActive('quiz_show_bgRef');
    addActive('quiz_show_floorRef');
    addActive('quiz_show_signRef');
    addActive('quiz_show_bigScreenBgRef');
  } else {
    removeActive('quiz_show_bgRef'); 
    removeActive('quiz_show_floorRef'); 
    removeActive('quiz_show_signRef'); 
    removeActive('quiz_show_bigScreenBgRef');  
  }



};

export default doBackgroundUpdate;