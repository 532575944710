//###############################################
//############### HANDLE OBS PAGE ###############
//###############################################

/////////////////////////////////////
////// NODE & NPM DEPENDENCIES //////
/////////////////////////////////////
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { io } from "socket.io-client";

////////////////////////
////// COMPONENTS //////
////////////////////////
import CurrentSceneName from '../components/overview/CurrentSceneName';
import ProjectorRoom from '../components/overview/ProjectorRoom';
import MemeMirror from '../components/overview/MemeMirror';
import NewsRoom from '../components/overview/NewsRoom';

///////////////////////////////
////// STYLED COMPONENTS //////
///////////////////////////////
import {
  OverviewFrame,
  OverviewWrapper
} from '../styledComponents/Overview.js';

//////////////////////////
////// THIS HANDLER //////
//////////////////////////
export default function HandleOverview() {

  const accessKey = process.env.REACT_APP_ACCESS_KEY;
  const webAccessKey = process.env.REACT_APP_WEB_ACCESS_KEY;

  const [sceneData, setSceneData] = useState({
    loading: true,
    meme_review: { active: false }
  });

  const [overlayData, setOverlayData] = useState({
    loading: true,
    chat: {
      active: false,
      changing: false,
      position: 0
    },
    music: {
      active: false
    },
    quiz_countdown: {
      active: false
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      const url = process.env.REACT_APP_STUDIO_API_URL + "/bodach/get-current-scene?t=" + webAccessKey;
      try {
        const response = await axios.get(url);

        if (typeof response === "object") {
          if (response.data.qry === 1) {
            setSceneData(response.data.res.currentData);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const url = process.env.REACT_APP_STUDIO_API_URL + "/bodach/get-current-overlay?t=" + webAccessKey;
      try {
        const response = await axios.get(url);

        if (typeof response === "object") {
          if (response.data.qry === 1) {
            setOverlayData(response.data.res.currentData);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_API_URL, {
      query: { accessKey },
      transports: ['websocket'],
      upgrade: false
    });

    socket.on("bodachSceneUpdate", data => {
      setSceneData(data);
    });

    return () => socket.disconnect();
  }, []);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_API_URL, {
      query: { accessKey },
      transports: ['websocket'],
      upgrade: false
    });

    socket.on("bodachOverlayUpdate", data => {
      setOverlayData(data);
    });

    return () => socket.disconnect();
  }, []); 

  if (overlayData.loading || sceneData.loading) { return null; }

  console.log(sceneData);

  return (
    <OverviewFrame>
      <OverviewWrapper>

        <CurrentSceneName sceneData={sceneData} />

        {sceneData.projector_room.active && (<ProjectorRoom />)}
        {sceneData.meme_review.active && (<MemeMirror />)}
        {sceneData.news_room.active && (<NewsRoom />)}

      </OverviewWrapper>
    </OverviewFrame>
  )

}