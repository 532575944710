//#####################################
//########### OVERVIEW SHOW ###########
//#####################################
import styled, { css } from 'styled-components';

/////////////////////////////////
////// STYLED VARS IMPORTS //////
/////////////////////////////////

//#########################
//### STYLED COMPONENTS ###
//#########################
export const OverviewFrame = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
  position: absolute;
  top:0;
  left:0;
  z-index: 4; 
  background-color: rgba(55,55,55,1);
`; 

export const OverviewWrapper = styled.main`
  margin:5rem 0 0 0;
  display: block;
  width: 100%;
  max-width: 1000px; 
  z-index: 4; 
  border:1px solid rgba(255, 255, 255, 0.25);
  text-align:center; 
`; 

export const Header = styled.h1`
  display: block;
  width: calc(100% - 4rem);  
  text-align:center;
  font-size: 2.5rem;
  padding: 2rem;
  border-bottom:1px solid rgba(255, 255, 255, 0.25);
`; 

export const OverviewContainer = styled.main`
  display: block;
  width: calc(100% - 4rem);  
  height:50vh;
  text-align:center;
  padding:2rem; 
  overflow-y: scroll;
`; 

export const Paragraph = styled.p`
  display: block;
  width: 100%;  
  text-align:left;
  font-size: 1.5rem; 
  margin-bottom: 1rem;
  font-family: "Titillium Web", sans-serif;
  line-height: 130%;
`; 