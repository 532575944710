//###############################################
//########## INSIDE SPOOKY ROOM MIRROR ##########
//###############################################

/////////////////////////////////////
////// NODE & NPM DEPENDENCIES //////
/////////////////////////////////////
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

///////////////////////////////
////// STYLED COMPONENTS //////
///////////////////////////////
import { MirrorMeme } from '../../../styledComponents/MemeRoom.js';

////////////////////////////
////// THIS COMPONENT //////
////////////////////////////
export default function MirrorMemes(props) {
  
  const webAccessKey = process.env.REACT_APP_WEB_ACCESS_KEY;

  const videoRef = useRef(null);

  const [showingMeme, setShowingMeme] = useState(false);
  const [slideType, setSlideType] = useState({
    type: "none",
    slideUrl: ""
  });

  const pingAPI = async (url) => {
    try {
      const response = await axios.get(url);
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {

    setShowingMeme(false);

    setTimeout(() => {
      setSlideType({
        type: "none",
        slideUrl: ""
      });
    }, 550);

    setTimeout(() => {
      setSlideType({
        type: props.currentSlide.slideType,
        url: props.currentSlide.slideUrl
      });
    }, 650);

    setTimeout(() => {
      setShowingMeme(true);
    }, 1500);

  }, [props.currentSlide]);
 
  useEffect(() => {
    if (videoRef.current) { 
      
      const video = videoRef.current;

      if (props.paused) {
        video.pause();
      } else {
        video.play();
      }
    }
  }, [props.paused]); 

  useEffect(() => {

    if (videoRef.current) {

      const video = videoRef.current;

      const handleVideoEnded = () => {

        let halfwayPoint = video.duration / 2;
        if (!isFinite(halfwayPoint) || isNaN(halfwayPoint)) {
          halfwayPoint = 3;  // Default to 3 seconds
        }
        
        video.currentTime = Math.round(halfwayPoint);
        video.pause();

        pingAPI(process.env.REACT_APP_STUDIO_API_URL + "/bodach/change-audio/1?t=" + webAccessKey);
      };

      // Add event listener to detect when the video ends
      video.addEventListener('ended', handleVideoEnded);

      // Clean up the event listener when the component is unmounted
      return () => {
        video.removeEventListener('ended', handleVideoEnded);
      };
    }

  }, [showingMeme]);

  return (
    <>
      {slideType.type === "image" ? (
        <MirrorMeme id="MirrorMeme"
          $active={showingMeme}
        >
          <div className="mirrorMemeInsetContainer" >
            <div className="mirrorMemeInsetShadow" />
            <img
              className="mirrorMemeImage"
              src={slideType.url}
            />
          </div>
        </MirrorMeme>
      ) : null}

      {slideType.type === "video" ? (
        <MirrorMeme id="MirrorMeme"
          $active={showingMeme}
        >
          <div className="mirrorMemeInsetContainer" >
            <div className="mirrorMemeInsetShadow" />
            <video
              ref={videoRef}
              className="mirrorMemeVideo"
              autoPlay
            >
              <source src={slideType.url} type="video/mp4" />
            </video>
          </div>
        </MirrorMeme>
      ) : null}

    </>
  )

}