//###################################
//########## INSIDE GYM TV ##########
//###################################

/////////////////////////////////////
////// NODE & NPM DEPENDENCIES //////
/////////////////////////////////////
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

///////////////////////////////
////// STYLED COMPONENTS //////
///////////////////////////////
import { QuizMeme } from '../../../styledComponents/QuizShow.js';

////////////////////////////
////// THIS COMPONENT //////
////////////////////////////
export default function GymTVMemes(props) {

  const webAccessKey = process.env.REACT_APP_WEB_ACCESS_KEY;

  const videoRef = useRef(null);
  const videoRef2 = useRef(null);

  const [showingMeme, setShowingMeme] = useState(false);
  const [slideType, setSlideType] = useState({
    type: "none",
    slideUrl: ""
  });

  const pingAPI = async (url) => {
    try {
      const response = await axios.get(url);
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {

    setShowingMeme(false);

    setTimeout(() => {
      setSlideType({
        type: "none",
        slideUrl: ""
      });
    }, 550);

    setTimeout(() => {
      setSlideType({
        type: props.currentSlide.slideType,
        url: props.currentSlide.slideUrl
      });
    }, 650);

    setTimeout(() => {
      setShowingMeme(true);
    }, 1200);

  }, [props.currentSlide]);

  useEffect(() => {
    if (videoRef.current) {

      const video = videoRef.current;

      if (props.paused) {
        video.pause();
      } else {
        video.play();
      }
    }
  }, [props.paused]);

  useEffect(() => {

    if (videoRef2.current) {

      const bgVideo = videoRef2.current;

      let halfwayPoint = bgVideo.duration / 2;
      if (!isFinite(halfwayPoint) || isNaN(halfwayPoint)) {
        halfwayPoint = 3;  // Default to 3 seconds
      }

      bgVideo.currentTime = Math.round(halfwayPoint);
      bgVideo.pause();

    }

    if (videoRef.current) {

      const video = videoRef.current;

      const handleVideoEnded = () => {

        let halfwayPoint = video.duration / 2;
      if (!isFinite(halfwayPoint) || isNaN(halfwayPoint)) {
        halfwayPoint = 3;  // Default to 3 seconds
      }
      
        video.currentTime = Math.round(halfwayPoint);
        video.pause();

        pingAPI(process.env.REACT_APP_STUDIO_API_URL + "/bodach/change-audio/1?t=" + webAccessKey);
      };

      // Add event listener to detect when the video ends
      video.addEventListener('ended', handleVideoEnded);

      // Clean up the event listener when the component is unmounted
      return () => {
        video.removeEventListener('ended', handleVideoEnded);
      };
    }

  }, [showingMeme]);


  return (
    <>
      {slideType.type === "image" ? (
        <QuizMeme
          $active={showingMeme}
        >
          <img
            className="quizSlideImage"
            src={slideType.url}
          />
          <img
            className="quizSlideImageBg"
            src={slideType.url}
          />
        </QuizMeme>
      ) : null}

      {slideType.type === "video" ? (
        <QuizMeme
          $active={showingMeme}
        >
          <video
            ref={videoRef}
            className="quizSlideImage"
            autoPlay
          >
            <source src={slideType.url} type="video/mp4" />
          </video>

          <video
            ref={videoRef2}
            className="quizSlideImageBg"
            muted
          >
            <source src={slideType.url} type="video/mp4" />
          </video>
        </QuizMeme>
      ) : null}

    </>
  )

}