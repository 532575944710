//#######################################
//############## CHAT SHOW ##############
//#######################################
import styled from 'styled-components'; 

//#########################
//### STYLED COMPONENTS ###
//#########################

////////////////////////////
///////// CANVASES /////////
////////////////////////////
export const TheChatShowFrame = styled.div`
  display: block;
  width: 3840px;
  height: 2160px;
  position: absolute;
  top:0;
  left:0;
  z-index: 9;  
`; 

////////////////////////////
//////// BACKGROUND ////////
////////////////////////////
export const ChatShowBg = styled.div`
  display: none;
  width: 3840px;
  height: 1446px;
  background-image: url('images/chat-show/background.png');
  background-size: cover;
  position: absolute;
  top:0;
  left:0;
  z-index: 40;    
  opacity: 0;
 
  &.active{
    opacity: 1; 
  } 
`;

export const ChatShowFloor = styled.div`
  display: none;
  width: 3840px;
  height: 714px;
  background-image: url('images/chat-show/floor.png');
  background-size: cover;
  position: absolute;
  bottom:0;
  left:0;
  z-index: 40; 
  opacity: 0;

  &.active{
    opacity: 1;   
  } 
`;

export const ChatShowSofaBg = styled.div`
  display: none;
  width: 2550px;
  height: 1771px;
  background-image: url('images/chat-show/sofa-tv-bg.png');
  background-size: cover;
  position: absolute;
  bottom:0;
  left:0;
  z-index: 40; 
  opacity: 0;

  &.active{
    opacity: 1; 
  }  
`;

////////////////////////////
//////// FOREGROUND ////////
////////////////////////////
export const ChatShowDesk = styled.div`
  display: none;
  width: 1902px;
  height: 1129px;
  background-image: url('images/chat-show/desk.png');
  background-size: cover;
  position: absolute;
  bottom:0;
  left:0;
  z-index: 40;  
  transform: translateX(1940px)translateY(-50px);
  opacity: 0;

  &.active{
    opacity: 1; 
    will-change: opacity;
  }  
`;

export const ChatShowSofa = styled.div`
  display: none;
  width: 2550px;
  height: 1771px;
  background-image: url('images/chat-show/sofa-tv.png');
  background-size: cover;
  position: absolute;
  bottom:0;
  left:0;
  z-index: 40; 
  opacity: 0;

  &.active{
    opacity: 1; 
  }  
`;