//###########################################
//########## SMEG TALKS FOREGROUND ##########
//###########################################

/////////////////////////////////////
////// NODE & NPM DEPENDENCIES //////
/////////////////////////////////////
import React from 'react';

////////////////////////
////// COMPONENTS //////
////////////////////////
import TheSmegSlidesContainer from '../backgrounds/smegSlides/TheSmegSlidesContainer.js';

///////////////////////////////
////// STYLED COMPONENTS //////
///////////////////////////////
import {
  TheSmegTalkFrame,
  SmegProjector
} from '../../styledComponents/SmegTalks.js';

////////////////////////////
////// THIS COMPONENT //////
////////////////////////////
export default function SmegTalksForeground() { 

  return (
    <TheSmegTalkFrame >

      <SmegProjector
        id='smeg_talk_smegProjectorRef'
        className='##########################'
      >
        <TheSmegSlidesContainer />
      </SmegProjector>

    </TheSmegTalkFrame>
  )
}