//###############################################
//############### HANDLE OBS PAGE ###############
//###############################################

/////////////////////////////////////
////// NODE & NPM DEPENDENCIES //////
/////////////////////////////////////
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { io } from "socket.io-client";
import Countdown from 'react-countdown';

////////////////////////
////// COMPONENTS //////
////////////////////////
import BackgroundMusic from '../components/BackgroundMusic.js';

///////////////////////////////
////// STYLED COMPONENTS //////
///////////////////////////////
import {
  Canvas1080
} from '../styledApp';
import {
  StreamChat,
  QuizCountdown,
  Sponsors
} from '../styledComponents/NoneScene.js';

//////////////////////////
////// THIS HANDLER //////
//////////////////////////
export default function HandleOverlays() {

  const accessKey = process.env.REACT_APP_ACCESS_KEY;
  const webAccessKey = process.env.REACT_APP_WEB_ACCESS_KEY;

  const [sceneData, setSceneData] = useState({
    loading: true,
  });

  const [overlayData, setOverlayData] = useState({
    loading: true,
    chat: {
      active: false,
      changing: false,
      position: 0
    },
    music: {
      active: false
    },
    quiz_countdown: {
      active: false
    }
  });

  const [chatVisibleState, setChatVisibleState] = useState(false);
  useEffect(() => {

    let timer;
    if (
      overlayData.chat.active === true
      && overlayData.chat.changing === false
    ) {
      setChatVisibleState(true);
    } else {
      setChatVisibleState(false);
    }
    return () => clearTimeout(timer);
  }, [overlayData.chat]);

  const [quizCountdownVisibleState, setQuizCountdownVisibleState] = useState(false);
  useEffect(() => {

    let timer;
    if (
      overlayData.quiz_countdown.active === true
    ) {
      setQuizCountdownVisibleState(true);
    } else {
      setQuizCountdownVisibleState(false);
    }
    return () => clearTimeout(timer);
  }, [overlayData.quiz_countdown]);

  const Completionist = () => <div className="quiz-countdown-complete">GET READY!</div>;

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render the completion state
      return <Completionist />;
    } else {
      // Format the countdown
      let timeString = '';

      // Include hours and minutes only if they are greater than zero
      if (hours > 0) {
        timeString += `${hours}:`;
      }
      if (minutes > 0 || hours > 0) {
        timeString += `${minutes < 10 && hours > 0 ? '0' : ''}${minutes}:`;
      }

      // Format seconds
      if (seconds < 10) {
        timeString += `${hours > 0 || minutes > 0 ? '0' : ''}${seconds}`;
      } else {
        timeString += seconds;
      }

      return <div className="quiz-countdown-timer">{timeString}</div>;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const url = process.env.REACT_APP_STUDIO_API_URL + "/bodach/get-current-scene?t=" + webAccessKey;
      try {
        const response = await axios.get(url);

        if (typeof response === "object") {
          if (response.data.qry === 1) {
            setSceneData(response.data.res.currentData);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const url = process.env.REACT_APP_STUDIO_API_URL + "/bodach/get-current-overlay?t=" + webAccessKey;
      try {
        const response = await axios.get(url);

        if (typeof response === "object") {
          if (response.data.qry === 1) {
            setOverlayData(response.data.res.currentData);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_API_URL, {
      query: { accessKey },
      transports: ['websocket'],
      upgrade: false
    });

    socket.on("bodachSceneUpdate", data => {
      setSceneData(data);
    });

    return () => socket.disconnect();
  }, []);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_API_URL, {
      query: { accessKey },
      transports: ['websocket'],
      upgrade: false
    });

    socket.on("bodachOverlayUpdate", data => {
      setOverlayData(data);
    });

    return () => socket.disconnect();
  }, []);
  
  if (overlayData.loading || sceneData.loading) { return null; }
  return (
    <Canvas1080>

      <BackgroundMusic
        sceneData={sceneData}
        overlayData={overlayData}
      />

      <QuizCountdown
        $visible={quizCountdownVisibleState}
      // $pos1={overlayData.chat.position === 1 ? true : false}
      // $pos2={overlayData.chat.position === 2 ? true : false}
      // $pos3={overlayData.chat.position === 3 ? true : false}
      // $pos4={overlayData.chat.position === 4 ? true : false} 
      >
        <img
          className="quiz-countdown-bg"
          src={process.env.REACT_APP_PUBLIC_URL + "/images/quiz-show/quiz-countdown.png"}
        />

        <div className="quiz-countdown-timer-label">QUIZ STARTING SOON</div>

        <Countdown
          date={overlayData.quiz_countdown.quiz_time}
          className="quiz-countdown-timer"
          daysInHours={true}
          //date={Date.now() + 2000}
          autoStart={true}
          renderer={renderer}
        >
          <Completionist />
        </Countdown>

        <div className="quiz-countdown-url">QUIZ.BODACH.SHOW</div>
        <div className="quiz-countdown-pin">PIN: {overlayData.quiz_countdown.quiz_pin}</div>

      </QuizCountdown>

      <StreamChat
        $visible={chatVisibleState}
        $pos1={overlayData.chat.position === 1 ? true : false}
        $pos2={overlayData.chat.position === 2 ? true : false}
        $pos3={overlayData.chat.position === 3 ? true : false}
        $pos4={overlayData.chat.position === 4 ? true : false}
      >
        <iframe
          src={overlayData.chat.chat_url}
          width="100%"
          height="100%"
          title="chat"
        ></iframe>
      </StreamChat>

      <Sponsors>
        <img
          src={process.env.REACT_APP_PUBLIC_URL + '/images/sponsors/1-imaginary-teapot.png'}
          className={[
            'sponsor-image',
            overlayData.sponsor.sponsor_id === 1 ? 'active' : 'hidden'
          ].join(' ')}
        />
        <img
          src={process.env.REACT_APP_PUBLIC_URL + '/images/sponsors/2-magic-health-pills.png'}
          className={[
            'sponsor-image',
            'shadow',
            overlayData.sponsor.sponsor_id === 2 ? 'active' : 'hidden'
          ].join(' ')}
        />
        <img
          src={process.env.REACT_APP_PUBLIC_URL + '/images/sponsors/3-magic-penis-pills.png'}
          className={[
            'sponsor-image',
            'shadow',
            overlayData.sponsor.sponsor_id === 3 ? 'active' : 'hidden'
          ].join(' ')}
        />
        <img
          src={process.env.REACT_APP_PUBLIC_URL + '/images/sponsors/4-magic-brain-pills.png'}
          className={[
            'sponsor-image',
            'shadow',
            overlayData.sponsor.sponsor_id === 4 ? 'active' : 'hidden'
          ].join(' ')}
        />
      </Sponsors>

    </Canvas1080>
  )
}