//#####################################
//############ COMEDY CLUB ############
//#####################################
import styled, { css } from 'styled-components';

/////////////////////////////////
////// STYLED VARS IMPORTS //////
/////////////////////////////////
import { easing } from './vars/scEasing';

//#########################
//### STYLED COMPONENTS ###
//#########################

////////////////////////////
///////// CANVASES /////////
////////////////////////////
export const TheNewsRoomFrame = styled.div`
  display: block;
  width: 3840px;
  height: 2160px;
  position: absolute;
  top:0;
  left:0;
  z-index: 6;  
`;  

////////////////////////////
//////// BACKGROUND ////////
////////////////////////////
export const NewsRoomBackgroundVideo = styled.div`
  display: none;
  width: 3840px;
  height: 2160px;
  position: absolute;
  top:0;
  left:0;
  z-index: 40;  
  opacity: 0;

  .news-room-bg-vid{
    display: block;
    width:100%;
    height:100%;
    position: absolute;
    left:0;
    top:0;
  }

  &.active{ 
    //transition: all 1500ms ${easing.easeOutCubic};
    //transition-delay: 100ms;
    opacity: 1; 
    //will-change: opacity;
  } 
`;

export const NewsRoomSlidesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 900px;
  height: 701px;
  position: absolute;
  top:0;
  right:0;
  z-index: 62;  
  overflow: hidden;
  filter: drop-shadow(15px 15px 5px rgba(0,0,0,0.5));   
  transform: translateX(-1095px)translateY(584px);
`;

export const NewsSlideShow = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 100%;
height: 100%; 
overflow:hidden; 
position: relative;  
z-index: 60;
opacity: 1; 

  .newsSlideImage{
    display: block;
    width:100%;
    height:100%;
    overflow: hidden; 
    object-fit: contain;
    opacity: 0;
    position: absolute;
    left:0;
    top:0;
  }

  .newsSlideVideo{
    display: block;
    width:100%;
    height:100%;
    overflow: hidden; 
    object-fit: contain;
    opacity: 0;
    position: absolute;
    left:0;
    top:0;
    z-index:5;
  } 

  .newsSlideVideoBlurBg{
    display: block;
    width:100%;
    height:100%;
    overflow: hidden; 
    object-fit: cover;
    opacity: 0;
    position: absolute;
    left:0;
    top:0;
    z-index:1;
    filter:brightness(25%)blur(5px);
  } 

  ${props =>
    props.$active &&
    css` 
      .newsSlideVideo, 
      .newsSlideImage,
      .newsSlideVideoBlurBg{
        opacity: 1;
        transition: all 500ms ${easing.easeOutCubic};
        transition-delay:500ms;
        will-change: opacity;
      }
      //transition: all 500ms ${easing.easeOutCubic};
      //will-change: transform;
    `}; 
`;


////////////////////////////
//////// FOREGROUND ////////
////////////////////////////
export const NewsDesk = styled.div`
  display: nope;
  width: 3840px;
  height: 880px;
  background-image: url('images/news-room/news_room_desk.png');
  background-size: cover;
  position: absolute;
  bottom:0;
  left:0;
  z-index: 52;  
  opacity: 0; 

  &.active{
    opacity: 1; 
    //transition: all 1000ms ${easing.easeOutCubic};
    //transition-delay: 450ms;
    //will-change: opacity;
  } 
`;