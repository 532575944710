//######################################
//############## THE NEST ##############
//######################################
import styled, { css } from 'styled-components';

/////////////////////////////////
////// STYLED VARS IMPORTS //////
/////////////////////////////////
import { easing } from './vars/scEasing';

//#########################
//### STYLED COMPONENTS ###
//#########################

////////////////////////////
///////// CANVASES /////////
//////////////////////////// 
export const TheNestFrame = styled.div`
  display: block;
  width: 3840px;
  height: 2160px;
  position: absolute;
  top:0;
  left:0;
  z-index: 1;
`;

////////////////////////////
//////// BACKGROUND ////////
////////////////////////////

export const NestBG = styled.div`
  display: none;
  width: 3840px;
  height: 2160px;
  background-size: cover;
  position: absolute;
  top:0;
  left:0;
  opacity: 0; 

  &.active{
    opacity: 1; 
    transition: all 500ms cubic-bezier(0.33, 1, 0.68, 1);
    transition-delay:150ms;
    will-change: opacity;
  } 
  ${props =>
    props.$layer1 &&
    css`
      background-image: url('images/the-nest/nest-bg-layer-1.png');
      z-index: 1; 
    `};

  ${props =>
    props.$layer2 &&
    css`
      background-image: url('images/the-nest/nest-bg-layer-2.png');
      z-index: 25; 
    `};

  ${props =>
    props.$layer3 &&
    css`
      background-image: url('images/the-nest/nest-bg-layer-3.png');
      z-index: 50; 
    `};  
    
`;

export const NestSofa = styled.div`
  display: none;
  width: 1967px;
  height: 956px;
  background-image: url('images/the-nest/nest-sofa.png');
  background-size: cover;
  position: absolute;
  top:0;
  left:0;
  z-index: 51; 
  transform: translateX(1057px)translateY(-2260px); 

  &.active{
    transform: translateX(1057px)translateY(1059px);
    transition: all 500ms ${easing.easeOutCubic};
    transition-delay:450ms;
    will-change: transform;
  }  
`;

export const NestChair = styled.div`
  display: none;
  width: 1278px;
  height: 1321px;
  background-image: url('images/the-nest/nest-chair.png');
  background-size: 100%;
  position: absolute;
  top:0;
  left:0;
  z-index: 52; 
  transform: translateX(2263px)translateY(2160px); 

  &.active{
    transform: translateX(2263px)translateY(849px);
    transition: all 500ms ${easing.easeOutCubic};
    transition-delay:675ms;
    will-change: transform;
  } 
`;

export const NestPhoto1 = styled.div`
  display: none;
  width: 275px;
  height: 332px; 
  background-size: 100%;
  position: absolute;
  top:0;
  left:0;
  z-index: 24; 
  transform: translateX(853px)translateY(397px)scale(0);  

  ${props =>
    props.$imageurl &&
    css`
      background-image: url(${props.$imageurl});
  `};

  &.active{
    transform: translateX(853px)translateY(397px)scale(1);
    transition: all 500ms cubic-bezier(0.34, 1.56, 0.64, 1); 
    transition-delay:650ms;
    will-change: transform;
  }  
`;

export const NestPhoto2 = styled.div`
  display: none;
  width: 318px;
  height: 405px; 
  background-size: 100%;
  position: absolute;
  top:0;
  left:0;
  z-index: 24; 
  transform: translateX(1324px)translateY(120px)scale(0); 

  ${props =>
    props.$imageurl &&
    css`
      background-image: url(${props.$imageurl});
  `};

  &.active{
    transform: translateX(1324px)translateY(120px)scale(1);
    transition: all 500ms cubic-bezier(0.34, 1.56, 0.64, 1); 
    transition-delay:750ms;
    will-change: transform;
  }  
`;

export const NestPhoto3 = styled.div`
  display: none;
  width: 483px;
  height: 322px; 
  background-size: 100%;
  position: absolute;
  top:0;
  left:0;
  z-index: 24; 
  transform: translateX(1241px)translateY(592px)scale(0);

  ${props =>
    props.$imageurl &&
    css`
      background-image: url(${props.$imageurl});
  `};

  &.active{
    transform: translateX(1241px)translateY(592px)scale(1);
    transition: all 500ms cubic-bezier(0.34, 1.56, 0.64, 1); 
    transition-delay:850ms;
    will-change: transform;
  }  
`;

export const NestPhoto4 = styled.div`
  display: none;
  width: 784px;
  height: 458px; 
  background-size: 100%;
  position: absolute;
  top:0;
  left:0;
  z-index: 24; 
  transform: translateX(2355px)translateY(120px)scale(0);

  ${props =>
    props.$imageurl &&
    css`
      background-image: url(${props.$imageurl});
  `};

  &.active{
    transform: translateX(2355px)translateY(120px)scale(1);
    transition: all 500ms cubic-bezier(0.34, 1.56, 0.64, 1); 
    transition-delay:950ms;
    will-change: transform;
  } 
`;

export const NestPhoto5 = styled.div`
  display: none;
  width: 582px;
  height: 399px; 
  background-size: 100%;
  position: absolute;
  top:0;
  left:0;
  z-index: 24; 
  transform: translateX(2347px)translateY(653px)scale(0);

  ${props =>
    props.$imageurl &&
    css`
      background-image: url(${props.$imageurl});
  `};

  &.active{
    transform: translateX(2347px)translateY(653px)scale(1);
    transition: all 500ms cubic-bezier(0.34, 1.56, 0.64, 1); 
    transition-delay:1050ms;
    will-change: transform;
  } 
`;

export const NestPlant1 = styled.div`
  display: none;
  width: 574px;
  height: 1199px;
  background-image: url('images/the-nest/nest-plant-1.png');
  background-size: 100%;
  position: absolute;
  top:0;
  left:0;
  z-index: 20; 
  transform: translateX(65px)translateY(-1400px);

  &.active{
    transform: translateX(65px)translateY(180px);
    transition: all 500ms ${easing.easeOutCubic};
    transition-delay:600ms;
    will-change: transform;
  }
`;

export const NestPlant2 = styled.div`
  display: none;
  width: 432px;
  height: 728px;
  background-image: url('images/the-nest/nest-plant-2.png');
  background-size: 100%;
  position: absolute;
  top:0;
  left:0;
  z-index: 19; 
  transform: translateX(958px)translateY(-2800px);

  &.active{
    transform: translateX(479px)translateY(599px);
    transition: all 500ms ${easing.easeOutCubic};
    transition-delay:700ms;
    will-change: transform;
  } 
`;

export const NestPlant3 = styled.div`
  display: none;
  width: 522px;
  height: 981px;
  background-image: url('images/the-nest/nest-plant-3.png');
  background-size: 100%;
  position: absolute;
  top:0;
  left:0;
  z-index: 40; 
  transform: translateX(3318px)translateY(-1400px);

  &.active{
    transform: translateX(3318px)translateY(479px);
    transition: all 500ms ${easing.easeOutCubic};
    transition-delay:800ms;
    will-change: transform;
  } 
`;

export const NestPlant4 = styled.div`
  display: none;
  width: 892px;
  height: 1298px;
  background-image: url('images/the-nest/nest-plant-4.png');
  background-size: 100%;
  position: absolute;
  top:0;
  left:0;
  z-index: 26; 
  transform: translateX(2896px)translateY(-1400px);

  &.active{
    transform: translateX(2896px)translateY(170px);
    transition: all 500ms ${easing.easeOutCubic};
    transition-delay:900ms;
    will-change: transform;
  } 
`;

export const NestTVbg = styled.div`
  display: none;
  width: 682px;
  height: 523px;
  background-image: url('images/the-nest/nest-tv-bg.png');
  background-size: cover;
  position: absolute;
  top:0;
  left:0;
  z-index: 55; 
  transform: translateX(-4361px)translateY(795px);

  &.active{
    transform: translateX(821px)translateY(795px);
    transition: all 500ms ${easing.easeOutCubic};
    transition-delay:775ms;
    will-change: transform;
  }
 
`;


////////////////////////////
//////// FOREGROUND ////////
////////////////////////////
export const NestDesk = styled.div`
  display: none;
  width: 1931px;
  height: 913px;
  background-image: url('images/the-nest/nest-desk.png');
  background-size: cover;
  position: absolute;
  top:0;
  left:0;
  z-index: 51; 
  transform: translateX(3840px)translateY(1248px);

  &.active{
    transform: translateX(1907px)translateY(1248px);
    transition: all 500ms ${easing.easeOutCubic};
    transition-delay:875ms;
    will-change: transform;
  }
 
`;

export const NestTV = styled.div`
  display: none;
  width: 1445px;
  height: 1512px;
  background-image: url('images/the-nest/nest-tv.png');
  background-size: cover;
  position: absolute;
  top:0;
  left:0;
  z-index: 50; 
  transform: translateX(-4701px)translateY(795px);

  &.active{
    transform: translateX(390px)translateY(655px);
    transition: all 500ms ${easing.easeOutCubic};
    transition-delay:775ms;
    will-change: transform;
  }
 
`;

export const NestPlant5 = styled.div`
  display: none;
  width: 892px;
  height: 1298px;
  background-image: url('images/the-nest/nest-plant-5.png');
  background-size: 100%;
  position: absolute;
  top:0;
  left:0;
  z-index: 51; 
  transform: translateX(-20px)translateY(2160px);

  &.active{
    transform: translateX(-20px)translateY(1050px);
    transition: all 500ms ${easing.easeOutCubic};
    transition-delay:975ms;
    will-change: transform;
  } 
`;

