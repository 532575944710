//##############################
//######## MEME MIRROR  ########
//##############################

///////////////////////////////
////// STYLED COMPONENTS //////
///////////////////////////////
import { 
  Paragraph,
  OverviewContainer,
} from '../../styledComponents/Overview.js';

////////////////////////////
////// THIS COMPONENT //////
////////////////////////////
export default function MemeMirror() {

  return (
    <OverviewContainer>
      <Paragraph>
        Welcome to the Basement, excuse the mess I haven’t had time to clean the place up.
      </Paragraph>
      <Paragraph>
        As you know, I found this mirror next to a note carrying 2 simple instructions.
      </Paragraph>
      <Paragraph>
        The first instruction said not to get too close to the mirror... it doesn't say way.
      </Paragraph>
      <Paragraph>
        And the second instruction was a phrase, when you say this phrase, the mirror shows you things.
      </Paragraph>
      <Paragraph>
        Mystery mirror on wall, show me some memes to corrupt my soul.
      </Paragraph>
    </OverviewContainer>
  )

}