//###############################################
//############### HANDLE OBS PAGE ###############
//###############################################
import React, { useState, useRef, useEffect } from 'react';

export default function BackgroundMusic(props) {

  let trackNum = 1;
  let lastTrack = 1;
  let scene = "intro";

  if (props.sceneData.projector_room.active) {
    trackNum = Math.floor(Math.random() * 37) + 1;
    lastTrack = 37;
    scene = "projector-room";
  }

  if (props.sceneData.nest.active) {
    trackNum = Math.floor(Math.random() * 37) + 1;
    lastTrack = 37;
    scene = "the-nest";
  }

  if (props.sceneData.meme_review.active) {
    trackNum = Math.floor(Math.random() * 37) + 1;
    lastTrack = 37;
    scene = "meme-room";
  }

  if (props.sceneData.comedy_club.active) {
    trackNum = Math.floor(Math.random() * 37) + 1;
    lastTrack = 37;
    scene = "jolen-stokes";
  }

  if (props.sceneData.chat_show.active) {
    trackNum = Math.floor(Math.random() * 37) + 1;
    lastTrack = 37;
    scene = "chat-show";
  }

  if (props.sceneData.quiz_show.active) {
    trackNum = Math.floor(Math.random() * 37) + 1;
    lastTrack = 37;
    scene = "quiz-show";
  }

  if (props.sceneData.news_room.active) {
    trackNum = Math.floor(Math.random() * 40) + 1;
    lastTrack = 40;
    scene = "news-room";
  }

  if (props.sceneData.smeg_talk.active) {
    trackNum = Math.floor(Math.random() * 22) + 1;
    lastTrack = 22;
    scene = "smeg-talks";
  }

  if (props.sceneData.gym.active) {
    trackNum = Math.floor(Math.random() * 20) + 1;
    lastTrack = 20;
    scene = "the-gym";
  }

  const audioRef = useRef(null);
  const [currentTrack, setCurrentTrack] = useState({
    currentTrack: trackNum,
    lastTrack: lastTrack,
    scene: scene
  });

  const [currentTrackUrl, setCurrentTrackUrl] = useState(process.env.REACT_APP_PUBLIC_URL + "/audio/intro/1.mp3");

  useEffect(() => {
    let timer;

    if (props.sceneData.projector_room.active) {
      const trackNum = Math.floor(Math.random() * 37) + 1;
      const lastTrack = 37;
      const scene = "projector-room";
      setCurrentTrack({
        currentTrack: trackNum,
        lastTrack: lastTrack,
        scene: scene
      })
    }

    if (props.sceneData.nest.active) {
      const trackNum = Math.floor(Math.random() * 37) + 1;
      const lastTrack = 37;
      const scene = "the-nest";
      setCurrentTrack({
        currentTrack: trackNum,
        lastTrack: lastTrack,
        scene: scene
      })
    }

    if (props.sceneData.meme_review.active) {
      const trackNum = Math.floor(Math.random() * 37) + 1;
      const lastTrack = 37;
      const scene = "meme-room";
      setCurrentTrack({
        currentTrack: trackNum,
        lastTrack: lastTrack,
        scene: scene
      })
    }

    if (props.sceneData.comedy_club.active) {
      const trackNum = Math.floor(Math.random() * 37) + 1;
      const lastTrack = 37;
      const scene = "jolen-stokes";
      setCurrentTrack({
        currentTrack: trackNum,
        lastTrack: lastTrack,
        scene: scene
      })
    }

    if (props.sceneData.chat_show.active) {
      const trackNum = Math.floor(Math.random() * 37) + 1;
      const lastTrack = 37;
      const scene = "chat-show";
      setCurrentTrack({
        currentTrack: trackNum,
        lastTrack: lastTrack,
        scene: scene
      })
    }

    if (props.sceneData.quiz_show.active) {
      const trackNum = Math.floor(Math.random() * 37) + 1;
      const lastTrack = 37;
      const scene = "quiz-show";
      setCurrentTrack({
        currentTrack: trackNum,
        lastTrack: lastTrack,
        scene: scene
      })
    }

    if (props.sceneData.news_room.active) {
      const trackNum = Math.floor(Math.random() * 14) + 1;
      const lastTrack = 14;
      const scene = "news-room";
      setCurrentTrack({
        currentTrack: trackNum,
        lastTrack: lastTrack,
        scene: scene
      })
    }

    if (props.sceneData.smeg_talk.active) {
      const trackNum = Math.floor(Math.random() * 22) + 1;
      const lastTrack = 22;
      const scene = "smeg-talks";
      setCurrentTrack({
        currentTrack: trackNum,
        lastTrack: lastTrack,
        scene: scene
      })
    }

    if (props.sceneData.gym.active) {
      const trackNum = Math.floor(Math.random() * 20) + 1;
      const lastTrack = 20;
      const scene = "the-gym";
      setCurrentTrack({
        currentTrack: trackNum,
        lastTrack: lastTrack,
        scene: scene
      })
    }

    if (props.overlayData.music.active === true) {

      setCurrentTrackUrl(
        process.env.REACT_APP_PUBLIC_URL + "/audio/" + currentTrack.scene + "/" + currentTrack.currentTrack + ".mp3"
      )

      audioRef.current.volume = 0;

      timer = setTimeout(() => {
        audioRef.current.play();
      }, 75);

      setTimeout(() => { audioRef.current.volume = 0; }, 100);
      setTimeout(() => { audioRef.current.volume = 0.005; }, 150);
      setTimeout(() => { audioRef.current.volume = 0.01; }, 200);
      setTimeout(() => { audioRef.current.volume = 0.015; }, 250);
      setTimeout(() => { audioRef.current.volume = 0.02; }, 300);
      setTimeout(() => { audioRef.current.volume = 0.025; }, 350);
      setTimeout(() => { audioRef.current.volume = 0.03; }, 400);
      setTimeout(() => { audioRef.current.volume = 0.035; }, 450);
      setTimeout(() => { audioRef.current.volume = 0.04; }, 500);

    } else {

      setTimeout(() => { audioRef.current.volume = 0.04; }, 100);
      setTimeout(() => { audioRef.current.volume = 0.035; }, 150);
      setTimeout(() => { audioRef.current.volume = 0.03; }, 200);
      setTimeout(() => { audioRef.current.volume = 0.025; }, 250);
      setTimeout(() => { audioRef.current.volume = 0.02; }, 300);
      setTimeout(() => { audioRef.current.volume = 0.015; }, 350);
      setTimeout(() => { audioRef.current.volume = 0.01; }, 400);
      setTimeout(() => { audioRef.current.volume = 0.005; }, 450);
      setTimeout(() => { audioRef.current.pause(); }, 500);

    }

    const audioElement = audioRef.current;
    if (audioElement) {
      audioElement.onended = () => {

        let nextTrack = currentTrack.currentTrack + 1;
        if (nextTrack > currentTrack.lastTrack) {
          nextTrack = 1;
        }

        setCurrentTrack(prevState => {
          return {
            ...prevState,
            currentTrack: nextTrack
          };
        })

        setTimeout(() => {
          setCurrentTrackUrl(
            process.env.REACT_APP_PUBLIC_URL + "/audio/" + currentTrack.scene + "/" + nextTrack + ".mp3"
          )
        }, 250);

        audioRef.current.volume = 0;
        timer = setTimeout(() => {
          audioRef.current.volume = 0.04;
          audioRef.current.play();
        }, 500);

      };
    }

    return () => {
      clearTimeout(timer);
      if (audioRef) {
        audioRef.onended = null;
      }
    };
  }, [
    currentTrackUrl,
    props.overlayData.music.active,
    props.sceneData.start.active,
    props.sceneData.projector_room.active,
    props.sceneData.nest.active,
    props.sceneData.projector_room.active,
    props.sceneData.meme_review.active,
    props.sceneData.comedy_club.active,
    props.sceneData.chat_show.active,
    props.sceneData.quiz_show.active,
    props.sceneData.news_room.active,
    props.sceneData.smeg_talk.active,
    props.sceneData.gym.active,
    props.sceneData.intermission.active,
    props.sceneData.end.active,
    props.sceneData.blank.active
  ]);

  return (
    <>
      <audio ref={audioRef} src={currentTrackUrl} volume="0" preload="metadata" />
    </>
  )

}