//#######################################
//############## QUIZ SHOW ##############
//#######################################
import styled, { css } from 'styled-components';

/////////////////////////////////
////// STYLED VARS IMPORTS //////
/////////////////////////////////
import { easing } from './vars/scEasing';

//#########################
//### STYLED COMPONENTS ###
//#########################

////////////////////////////
///////// CANVASES /////////
////////////////////////////
export const TheQuizShowFrame = styled.div`
  display: block;
  width: 3840px;
  height: 2160px;
  position: absolute;
  top:0;
  left:0;
  z-index: 4; 
`; 

////////////////////////////
//////// BACKGROUND ////////
////////////////////////////
export const QuizShowBg = styled.div`
  display: none;
  width: 3840px;
  height: 2160px;
  background-image: url('images/quiz-show/background.png');
  background-size: cover;
  position: absolute;
  top:0;
  left:0;
  z-index: 40;  
  opacity:0;

  &.active{
    opacity:1; 
  } 
`;

export const QuizShowFloor = styled.div`
  display: none;
  width: 3840px;
  height: 742px;
  background-image: url('images/quiz-show/floor.png');
  background-size: cover;
  position: absolute;
  bottom:0;
  left:0;
  z-index: 41;  
  opacity:0;

  &.active{
    opacity:1;  
  }  
`;

export const QuizShowSign = styled.div`
  display: none;
  width: 1611px;
  height: 1209px;
  background-image: url('images/quiz-show/silly-sausages.png');
  background-size: cover;
  position: absolute;
  top:0;
  left:0;
  z-index: 41; 
  opacity:0;

  &.active{
    opacity:1; 
  } 
`;

export const QuizShowBigScreenBg = styled.div`
  display: none;
  width: 2444px;
  height: 1506px;
  background-image: url('images/quiz-show/big-screen-bg.png');
  background-size: cover;
  position: absolute;
  bottom:0;
  right:0;
  z-index: 41; 
  transform: translateX(0px)translateY(-450px); 
  opacity:0;

  &.active{
    opacity:1; 
  }
`;


////////////////////////////
//////// FOREGROUND ////////
////////////////////////////
export const QuizShowDesk = styled.div`
  display: none;
  width: 1504px;
  height: 671px;
  background-image: url('images/quiz-show/desk.png');
  background-size: cover;
  position: absolute;
  bottom:0;
  left:0;
  z-index: 41;  
  transform: translateX(0px)translateY(-340px); 
  opacity:0;

  &.active{
    opacity:1; 
  }
`;

export const QuizShowBigScreen = styled.div`
  display: none;
  width: 2444px;
  height: 1506px;
  background-image: url('images/quiz-show/big-screen.png');
  background-size: cover;
  position: absolute;
  bottom:0;
  right:0;
  z-index: 41; 
  transform: translateX(0px)translateY(-450px); 
  opacity:0;

  &.active{
    opacity:1; 
  }
`;

export const QuizSlidesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2100px;
  height: 1130px;
  position: absolute;
  top:0;
  left:0;
  z-index: 30; 
  transform: translateX(1650px)translateY(350px); 
`;

export const QuizMeme = styled.div`
  display: flex;
  align-items: left;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top:0;
  left:0;
  z-index: 1; 
  overflow: hidden;
  opacity: 0; 
  
  .quizSlideImage{
    display: block;
    width:100%;
    height: 100%;
    position: absolute;
    left: 0;
    top:0;
    object-fit: contain;
    z-index: 5;
  }
  
  .quizSlideImageBg{
    display: block;
    width:100%;
    height: 100%;
    position: absolute;
    left: 0;
    top:0;
    object-fit: cover;
    z-index: 1;
    filter:brightness(25%)blur(10px);
  }

  &.active{ 
   transition: all 500ms ${easing.easeOutCubic};
   opacity: 1; 
   will-change: opacity;
  }  

${props =>
  props.$active &&
  css`
     transition: all 500ms ${easing.easeOutCubic};
     opacity: 1; 
     will-change: opacity;
  `};
`;
