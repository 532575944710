//###############################################
//############### NEST BACKGROUND ###############
//###############################################

/////////////////////////////////////
////// NODE & NPM DEPENDENCIES //////
/////////////////////////////////////
import React from 'react';

///////////////////////////////
////// STYLED COMPONENTS //////
///////////////////////////////
import {
  TheNestFrame,
  NestChair, 
} from '../../styledComponents/TheNest.js';
 
////////////////////////////
////// THIS COMPONENT //////
////////////////////////////
export default function NestMidGround() { 

  return (
    <TheNestFrame>

      <NestChair
        id='nest-chairRef' 
      />

    </TheNestFrame>
  )
}