//#####################################
//############ COMEDY CLUB ############
//#####################################
import styled from 'styled-components';

/////////////////////////////////
////// STYLED VARS IMPORTS //////
/////////////////////////////////
import { easing } from './vars/scEasing';

//#########################
//### STYLED COMPONENTS ###
//#########################

////////////////////////////
///////// CANVASES /////////
////////////////////////////
export const TheComedyClubFrame = styled.div`
  display: block;
  width: 3840px;
  height: 2160px;
  position: absolute;
  top:0;
  left:0;
  z-index: 8;  
`;

////////////////////////////
//////// BACKGROUND ////////
////////////////////////////
export const ComedyClubLeftCurtain = styled.div`
  display: none;
  width: 801px;
  height: 1948px;
  background-image: url('images/jolen-stokes/left-curtain.png');
  background-size: cover;
  position: absolute;
  top:0;
  left:0;
  z-index: 51; 
  transform: translateX(-850px)translateY(0px); 

  &.active{
    transform: translateX(0px)translateY(0px);
    //transition: all 500ms ${easing.easeOutCubic}; 
    //transition-delay: 100ms;
    //will-change: transform;
  } 
`;

export const ComedyClubRightCurtain = styled.div`
  display: none;
  width: 799px;
  height: 1979px;
  background-image: url('images/jolen-stokes/right-curtain.png');
  background-size: cover;
  position: absolute;
  top:0;
  right:0;
  z-index: 51; 
  transform: translateX(850px)translateY(0px); 

  &.active{
    transform: translateX(0px)translateY(0px);
    //transition: all 500ms ${easing.easeOutCubic};
    //transition-delay: 100ms;
    //will-change: transform; 
  } 
`;

export const ComedyClubWall = styled.div`
  display: none;
  width: 2928px;
  height: 1848px;
  background-image: url('images/jolen-stokes/wall.png');
  background-size: cover;
  position: absolute;
  top:0;
  left:0;
  z-index: 45;  
  transform: translateX(456px)translateY(0px); 
  opacity:0;

  &.active{    
    opacity:1;
    //transition: all 500ms ${easing.easeOutCubic}; 
    //transition-delay: 450ms;
    //will-change: opacity;
  } 
`;

export const ComedyClubSign = styled.div`
  display: none;
  width: 1453px;
  height: 1057px;
  background-image: url('images/jolen-stokes/jolen-stokes.png');
  background-size: cover;
  position: absolute;
  top:0;
  left:0;
  z-index: 45; 
  transform: translateX(1193px)translateY(100px)scale(0); 
  opacity: 0;

  &.active{
    transform: translateX(1193px)translateY(100px)scale(1);
    //transition: all 500ms ${easing.easeOutBack};
    //transition-delay: 750ms;
    opacity: 1;
    //will-change: transform, opacity;
  } 
`;

export const ComedyClubFloor = styled.div`
  display: none;
  width: 3840px;
  height: 725px;
  background-image: url('images/jolen-stokes/floor.png');
  background-size: cover;
  position: absolute;
  bottom:0px;
  left:0px;
  z-index: 30;  
  transform: translateX(0px)translateY(0px); 
  opacity:0;

  &.active{    
    opacity:1;
    //transition: all 1000ms ${easing.easeOutCubic};
    //transition-delay: 350ms;
    //will-change: opacity;
  } 
`;


////////////////////////////
//////// FOREGROUND ////////
////////////////////////////
export const ComedyClubMic = styled.div`
  display: none;
  width: 316px;
  height: 1214px;
  background-image: url('images/jolen-stokes/mic-stand.png');
  background-size: cover;
  position: absolute;
  bottom:0;
  left:0;
  z-index: 30; 
  transform: translateX(1730px)translateY(-3500px); 

  &.active{
    transform: translateX(1730px)translateY(-100px);
    //transition: all 500ms ${easing.easeOutCubic};
    //transition-delay: 950ms;
    //will-change: transform; 
  } 
`;

export const ComedyClubSpeaker1 = styled.div`
  display: none;
  width: 778px;
  height: 490px;
  background-image: url('images/jolen-stokes/left_speaker.png');
  background-size: cover;
  position: absolute;
  bottom:0;
  left:0;
  z-index: 50; 
  transform: translateX(150px)translateY(-2648px); 

  &.active{
    transform: translateX(150px)translateY(-10px);
    //transition: all 500ms ${easing.easeOutCubic}; 
    //transition-delay: 1550ms;
    //will-change: transform;
  } 
`;

export const ComedyClubSpeaker2 = styled.div`
  display: none;
  width: 778px;
  height: 490px;
  background-image: url('images/jolen-stokes/left_center_speaker.png');
  background-size: cover;
  position: absolute;
  bottom:0;
  left:0;
  z-index: 50; 
  transform: translateX(1000px)translateY(-2648px); 

  &.active{
    transform: translateX(1000px)translateY(120px);
    //transition: all 500ms ${easing.easeOutCubic}; 
    //transition-delay: 1150ms;
    //will-change: transform;
  } 
`;

export const ComedyClubSpeaker3 = styled.div`
  display: none;
  width: 774px;
  height: 488px;
  background-image: url('images/jolen-stokes/right_center_speaker.png');
  background-size: cover;
  position: absolute;
  bottom:0;
  right:0;
  z-index: 50; 
  transform: translateX(-1000px)translateY(-2648px); 

  &.active{
    transform: translateX(-1000px)translateY(120px);
    //transition: all 500ms ${easing.easeOutCubic}; 
    //transition-delay: 1350ms;
    //will-change: transform;
  } 
`;

export const ComedyClubSpeaker4 = styled.div`
  display: none;
  width: 774px;
  height: 488px;
  background-image: url('images/jolen-stokes/right_speaker.png');
  background-size: cover;
  position: absolute;
  bottom:0;
  right:0;
  z-index: 50; 
  transform: translateX(-150px)translateY(-2648px); 

  &.active{
    transform: translateX(-150px)translateY(-10px);
    //transition: all 500ms ${easing.easeOutCubic}; 
    //transition-delay: 1650ms;
    //will-change: transform;
  } 
`;