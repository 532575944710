//######################################################
//############### COMEDY CLUB FOREGROUND ###############
//######################################################

/////////////////////////////////////
////// NODE & NPM DEPENDENCIES //////
/////////////////////////////////////
import React from 'react';

///////////////////////////////
////// STYLED COMPONENTS //////
///////////////////////////////
import {
  TheComedyClubFrame,
  ComedyClubMic,
  ComedyClubSpeaker1,
  ComedyClubSpeaker2,
  ComedyClubSpeaker3,
  ComedyClubSpeaker4
} from '../../styledComponents/ComedyClub.js';

////////////////////////////
////// THIS COMPONENT //////
////////////////////////////
export default function ComedyClubForeground() {

  return (
    <TheComedyClubFrame>

      <ComedyClubMic
        id='comedy_club_micRef'  
      />

      <ComedyClubSpeaker1
        id='comedy_club_speaker1Ref' 
      />

      <ComedyClubSpeaker2
        id='comedy_club_speaker2Ref'  
      />

      <ComedyClubSpeaker3
        id='comedy_club_speaker3Ref'  
      />

      <ComedyClubSpeaker4
        id='comedy_club_speaker4Ref'  
      />

    </TheComedyClubFrame>
  )
}