//##################################
//#### BODACH STYLED COMPONENTS ####
//##################################
import styled from 'styled-components';

export const vars = {
  white: "rgba(255, 255, 255, 1)"
}
//${vars['white']};

export const Canvas = styled.div`
  display: block;
  width: 3840px;
  height: 2160px;
  position: absolute;
  top:0;
  left:0;
`;

export const Canvas1080 = styled.div`
  display: block;
  width: 1920px;
  height: 1080px;
  position: absolute;
  top:0;
  left:0;
`;


export const Div = styled.div`
  display: block; 
`;


//////////////////////////
//////// ELEMENTS ////////
//////////////////////////
 