//##################################
//########### SMEG TALKS ###########
//##################################
import styled, { css } from 'styled-components';

/////////////////////////////////
////// STYLED VARS IMPORTS //////
/////////////////////////////////
import { easing } from './vars/scEasing';

//#########################
//### STYLED COMPONENTS ###
//#########################

////////////////////////////
///////// CANVASES /////////
////////////////////////////
export const TheSmegTalkFrame = styled.div`
  display: block;
  width: 3840px;
  height: 2160px;
  position: absolute;
  top:0;
  left:0;
  z-index: 3;  
`; 

////////////////////////////
//////// BACKGROUND ////////
////////////////////////////
export const SmegCurtain = styled.div`
  display: none;
  width: 3840px;
  height: 1463px;
  background-image: url('images/smeg-talks/bg-curtain.png');
  background-size: cover;
  position: absolute;
  top:0;
  left:0;
  z-index: 40;  
  transform: translateX(0px)translateY(0px); 
  opacity: 0;

  &.active{
    opacity: 1; 
  } 
`;

export const SmegFloor = styled.div`
  display: none;
  width: 3840px;
  height: 964px;
  background-image: url('images/smeg-talks/floor.png');
  background-size: cover;
  position: absolute;
  bottom:0;
  left:0;
  z-index: 30;  
  transform: translateX(0px)translateY(0px); 
  opacity: 0;

  &.active{
    opacity: 1; 
  } 
`;

export const SmegSign = styled.div`
  display: none;
  width: 2151px;
  height: 885px;
  background-image: url('images/smeg-talks/smeg-sign.png');
  background-size: cover;
  position: absolute;
  bottom:0;
  left:0;
  z-index: 60;  
  transform: translateX(75px)translateY(-550px); 
  opacity: 0;

  &.active{
    opacity: 1;
  } 
`;

export const SmegRug = styled.div`
  display: none;
  width: 1602px;
  height: 549px;
  background-image: url('images/smeg-talks/rug.png');
  background-size: cover;
  position: absolute;
  bottom:0;
  left:0;
  z-index: 60;  
  transform: translateX(800px)translateY(-100px);  
  opacity: 0;

  &.active{
    opacity: 1; 
  }
`;


////////////////////////////
//////// FOREGROUND ////////
////////////////////////////
export const SmegProjector = styled.div`
  display: none;
  width: 1526px;
  height: 1597px;
  position: absolute;
  bottom:0;
  right:0;
  z-index: 60; 
  transform: translateX(-310px)translateY(-200px); 
  opacity: 0;

  &.active{
    opacity: 1; 
  }
`;

export const SmegSlidesProjectorScreen = styled.div`
  display: block;
  width: 1526px;
  height: 1597px;
  background-image: url('images/smeg-talks/projector-screen.png');
  background-size: cover;
  position: absolute;
  top:0;
  left:0;
  z-index: 2; 
`;

export const SmegSlidesContainer = styled.div`
  display: block;
  width: 1360px;
  height: 820px;
  position: absolute;
  top:0;
  left:0;
  background-color: rgba(0,0,0,1);
  z-index: 1; 
  overflow: hidden;
  transform: translateX(90px)translateY(75px); 
`;

export const SmegSlideShow = styled.div`
  display: flex;
  align-items: left;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top:0;
  left:0;
  z-index: 1; 
  overflow: hidden; 
  opacity: 0; 
  
  .smegSlideImage{
    display: block;
    width:100%;
    height: 100%;
    position: absolute;
    left: 0;
    top:0;
    object-fit: contain; 
    opacity: 1; 
    z-index: 5;
  } 

  .smegSlideImageBG{
    display: block;
    width:100%;
    height: 100%;
    position: absolute;
    left: 0;
    top:0;
    object-fit: cover; 
    opacity: 1; 
    z-index: 1;
    filter:brightness(25%)blur(10px);
  }
  
  .smegSlideVideo{
    display: block;
    width:100%;
    height: 100%;
    position: absolute;
    left: 0;
    top:0;
    object-fit: contain; 
    opacity: 1; 
    z-index: 5;
  } 

  .smegSlideVideoBG{
    display: block;
    width:100%;
    height: 100%;
    position: absolute;
    left: 0;
    top:0;
    object-fit: cover; 
    opacity: 1; 
    z-index: 1;
    filter:brightness(25%)blur(10px);
  }

  ${props =>
    props.$active &&
    css`
      transition: all 500ms ${easing.easeOutCubic}; 
      opacity: 1; 
      will-change: opacity;
      transition-delay:500ms;
  `}; 

  &.active{ 
    transition: all 500ms ${easing.easeOutCubic}; 
    opacity: 1; 
    will-change: opacity;
    transition-delay:500ms;
  } 
`;