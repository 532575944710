//####################################
//########## GYM FOREGROUND ##########
//####################################

/////////////////////////////////////
////// NODE & NPM DEPENDENCIES //////
/////////////////////////////////////
import React from 'react';

///////////////////////////////
////// STYLED COMPONENTS //////
///////////////////////////////
import {
  TheGymFrame,
  GymLeftKit,
  GymRightKit
} from '../../styledComponents/TheGym.js';

////////////////////////////
////// THIS COMPONENT //////
////////////////////////////
export default function GymBackground() {
  
  return (
    <TheGymFrame>

      <GymLeftKit
        id='gym_gymLeftKitRef' 
      />

      <GymRightKit
        id='gym_gymRightKitRef' 
      />

    </TheGymFrame>
  )
}