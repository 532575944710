//###################################
//########## PRJECTOR ROOM ##########
//###################################
import styled, { css } from 'styled-components';

/////////////////////////////////
////// STYLED VARS IMPORTS //////
/////////////////////////////////
import { easing } from './vars/scEasing';

//#########################
//### STYLED COMPONENTS ###
//#########################

////////////////////////////
///////// CANVASES /////////
////////////////////////////
export const TheProjectorRoomFrame = styled.div`
  display: block;
  width: 3840px;
  height: 2160px;
  position: absolute;
  top:0;
  left:0;
  z-index: 5; ; 
`;

////////////////////////////
//////// BACKGROUND ////////
////////////////////////////
export const ProjectorRoomBG = styled.div`
  display: none;
  width: 3840px;
  height: 2160px;
  background-size: cover;
  position: absolute;
  top:0;
  left:0; 
  z-index: 1; 
  background-image: url('images/projector-room/projector-room-bg.png');
  opacity: 0;

  &.active{ 
    transition: all 500ms ${easing.easeOutCubic};
    opacity: 1;
    transition-delay: 100ms;
    will-change: opacity;
  } 
`;


////////////////////////////
//////// FOREGROUND ////////
////////////////////////////
export const ProjectorRoomTable = styled.div`
  display: none;
  width: 3203px;
  height: 608px;
  background-size: cover;
  position: absolute;
  top:0;
  left:0;
  transform: translateX(3840px)translateY(1552px);
  z-index: 2; 
  background-image: url('images/projector-room/projector-room-table.png'); 

  &.active{
    transform: translateX(637px)translateY(1555px);
    transition: all 500ms ${easing.easeOutCubic};
    transition-delay: 450ms;
    will-change: transform;
  } 
`;

export const AgendaItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  right:1000px;
  width: 1000px;
  height: 200px; 
  position: absolute;
  background: radial-gradient(circle, rgba(61,182,217,0.6) 0%, rgba(63,187,223,0.6) 45%, rgba(51,154,184,0.0) 100%);
  transform: translateX(0px)translateY(220px);
  z-index: 30;  
  font-size: 3rem;
  font-weight: 400;
  text-transform: uppercase;
  border-radius: 5rem;
  text-shadow: 20px 20px 20px rgba(0,0,0,0.6); 
  opacity: 0;

  ${props =>
    props.$item1 &&
    css`
      bottom:740px;
      transform: translateX(0px)translateY(0px);
      transition: all 300ms ${easing.easeOutCubic};
      opacity: 0.9;
  `}; 

  ${props =>
    props.$item2 &&
    css`
      bottom:740px;
      transform: translateX(0px)translateY(-220px);
      transition: all 300ms ${easing.easeOutCubic};
      opacity: 0.8;
  `};  

  ${props =>
    props.$item3 &&
    css`
    bottom:740px;
    transform: translateX(0px)translateY(-440px);
    transition: all 300ms ${easing.easeOutCubic};
    opacity: 0.7;
  `};   

  ${props =>
    props.$item4 &&
    css`
    bottom:740px;
    transform: translateX(0px)translateY(-660px);
    transition: all 300ms ${easing.easeOutCubic};
    opacity: 0.0;
  `};  

  ${props =>
    props.$item5 &&
    css`
    bottom:740px;
    transform: translateX(0px)translateY(-880px);
    transition: all 300ms ${easing.easeOutCubic};
    opacity: 0;
  `};  

`;