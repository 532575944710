//###############################################
//########## PROJECTOR ROOM FOREGROUND ##########
//###############################################
import React from 'react';
import {
  TheMemeRoomFrame,
  MemeRoomLeftChair,
  MemeRoomRightChair
} from '../../styledComponents/MemeRoom.js';

export default function MemeRoomForeground() { 
 
  return (
    <TheMemeRoomFrame >

      <MemeRoomLeftChair
        id='meme_room_memeRoomLeftChairRef'
      />

      <MemeRoomRightChair
        id='meme_room_memeRoomRightChairRef'
      />

    </TheMemeRoomFrame>
  )
}