//######################################################
//############### COMEDY CLUB BACKGROUND ###############
//######################################################

/////////////////////////////////////
////// NODE & NPM DEPENDENCIES //////
/////////////////////////////////////
import React from 'react';

///////////////////////////////
////// STYLED COMPONENTS //////
///////////////////////////////
import {
  TheComedyClubFrame,
  ComedyClubLeftCurtain,
  ComedyClubRightCurtain,
  ComedyClubWall,
  ComedyClubSign,
  ComedyClubFloor
} from '../../styledComponents/ComedyClub.js';

////////////////////////////
////// THIS COMPONENT //////
////////////////////////////
export default function ComedyClubBackground() { 

  return (
    <TheComedyClubFrame>
      
      <ComedyClubLeftCurtain
        id='comedy_club_leftCurtainRef' 
      />
      
      <ComedyClubRightCurtain
        id='comedy_club_rightCurtainRef' 
      />
      
      <ComedyClubWall
        id='comedy_club_wallRef' 
      />
      
      <ComedyClubSign
        id='comedy_club_signRef' 
      />
      
      <ComedyClubFloor
        id='comedy_club_floorRef' 
      />

    </TheComedyClubFrame>
  )
}