//###############################################
//########## PROJECTOR ROOM BACKGROUND ##########
//###############################################
import React from 'react';
import InsideMirror from './memeMirror/InsideMirror.js';
import {
  TheMemeRoomFrame,
  MemeRoomFloor,
  MemeRoomWall,
  MemeRoomMirror
} from '../../styledComponents/MemeRoom.js';

export default function ProjectorRoomBackground() {

  return (
    <TheMemeRoomFrame>

      <MemeRoomFloor
        id="meme_room_memeRoomFloorRef"
      />

      <MemeRoomWall
        id="meme_room_memeRoomWallRef"
      />

      <MemeRoomMirror
        id="meme_room_memeRoomMirrorRef"
      >
        <InsideMirror />
      </MemeRoomMirror>

    </TheMemeRoomFrame>
  )
}