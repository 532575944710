//######################################
//######## DO MID-GROUND UPDATE  #######
//######################################

///////////////////////////////
////// IN-LINE FUNCTIONS //////
///////////////////////////////
async function addActive(id) {
  const element = document.getElementById(id);

  if (element) {
    element.classList.add('prep');
  }

  setTimeout(() => {
    if (element) {
      element.classList.add('active');
    }
  }, 75);
}

async function removeActive(id) {
  const element = document.getElementById(id);
  if (element) {
    element.classList.remove('active');
    element.classList.remove('prep');
  }
}

///////////////////////////
////// THIS FUNCTION //////
///////////////////////////
const doMidGroundUpdate = async (data) => {

  // NEST ELEMENTS
  if (data.nest.active) {
    addActive('nest-chairRef'); 
  } else {   
    removeActive('nest-chairRef');  
  }



  


};

export default doMidGroundUpdate;