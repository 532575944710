//####################################################
//############### CHAT SHOW FOREGROUND ###############
//####################################################

/////////////////////////////////////
////// NODE & NPM DEPENDENCIES //////
/////////////////////////////////////
import React from 'react';

///////////////////////////////
////// STYLED COMPONENTS //////
///////////////////////////////
import {
  TheChatShowFrame,
  ChatShowDesk,
  ChatShowSofa
} from '../../styledComponents/ChatShow.js';

////////////////////////////
////// THIS COMPONENT //////
////////////////////////////
export default function ChatShowForeground() {
 
  return (
    <TheChatShowFrame >

      <ChatShowDesk
        id='chat_show_deskRef' 
      />

      <ChatShowSofa
        id='chat_show_sofaRef' 
      />

    </TheChatShowFrame>
  )
}