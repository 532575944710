//###########################################
//########## PROJECTOR ROOM AGENDA ##########
//###########################################

/////////////////////////////////////
////// NODE & NPM DEPENDENCIES //////
/////////////////////////////////////
import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';
import { io } from "socket.io-client";

///////////////////////////////
////// STYLED COMPONENTS //////
///////////////////////////////
import {
  AgendaItem
} from '../../../styledComponents/ProjectorRoom.js';

////////////////////////////
////// THIS COMPONENT //////
////////////////////////////
export default function Agenda() {

  const accessKey = process.env.REACT_APP_ACCESS_KEY;
  const webAccessKey = process.env.REACT_APP_WEB_ACCESS_KEY;

  const [sceneData, setSceneData] = useState({
    loading: true,
  });

  useEffect(() => {
    const fetchData = async () => {
      const url = process.env.REACT_APP_STUDIO_API_URL + "/bodach/get-current-scene?t=" + webAccessKey;
      try {
        const response = await axios.get(url);

        if (typeof response === "object") {
          if (response.data.qry === 1) {
            setSceneData(response.data.res.currentData);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_API_URL, {
      query: { accessKey },
      transports: ['websocket'],
      upgrade: false
    });

    socket.on("bodachSceneUpdate", data => { 
      setSceneData(data);
    });

    return () => socket.disconnect();
  }, []);



  const agendaItem1Ref = useRef();
  const agendaItem2Ref = useRef();
  const agendaItem3Ref = useRef();
  const agendaItem4Ref = useRef();
  const agendaItem5Ref = useRef();



  if (sceneData.loading) { return null; }
  return (
    <>

      {sceneData.projector_room.active ? (
        <>
          <AgendaItem ref={agendaItem1Ref}
            $item1={sceneData.current_slide === 1}
            $item2={sceneData.current_slide === 2}
            $item3={sceneData.current_slide === 3}
            $item4={sceneData.current_slide === 4}
            $item5={sceneData.current_slide === 5}
          >
            {sceneData.projector_room.agenda.item_1}
          </AgendaItem>

          <AgendaItem ref={agendaItem2Ref}
            $item1={sceneData.current_slide === 2}
            $item2={sceneData.current_slide === 3}
            $item3={sceneData.current_slide === 4}
            $item4={sceneData.current_slide === 5}
          >
            {sceneData.projector_room.agenda.item_2}
          </AgendaItem>

          <AgendaItem ref={agendaItem3Ref}
            $item1={sceneData.current_slide === 3}
            $item2={sceneData.current_slide === 4}
            $item3={sceneData.current_slide === 5}
          >
            {sceneData.projector_room.agenda.item_3}
          </AgendaItem>

          <AgendaItem ref={agendaItem4Ref}
            $item1={sceneData.current_slide === 4}
            $item2={sceneData.current_slide === 5}
          >
            {sceneData.projector_room.agenda.item_4}
          </AgendaItem>

          <AgendaItem ref={agendaItem5Ref}
            $item1={sceneData.current_slide === 5}
          >
            {sceneData.projector_room.agenda.item_5}
          </AgendaItem>
        </>
      ) : null}
 

    </>
  )
}