//###############################################
//########## PROJECTOR ROOM FOREGROUND ##########
//###############################################

/////////////////////////////////////
////// NODE & NPM DEPENDENCIES //////
/////////////////////////////////////
import React, { useRef, useEffect } from 'react';

///////////////////////////////
////// STYLED COMPONENTS //////
///////////////////////////////
import {
  TheProjectorRoomFrame,
  ProjectorRoomTable
} from '../../styledComponents/ProjectorRoom.js';

////////////////////////////
////// THIS COMPONENT //////
////////////////////////////
export default function ProjectorRoomForeground() {
 
  return (
    <TheProjectorRoomFrame > 

      <ProjectorRoomTable id='pr-tableRef'/>

    </TheProjectorRoomFrame>
  )
}