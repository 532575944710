//####################################################
//############### CHAT SHOW BACKGROUND ###############
//####################################################

/////////////////////////////////////
////// NODE & NPM DEPENDENCIES //////
/////////////////////////////////////
import React from 'react';

///////////////////////////////
////// STYLED COMPONENTS //////
///////////////////////////////
import {
  TheChatShowFrame,
  ChatShowFloor,
  ChatShowBg,
  ChatShowSofaBg
} from '../../styledComponents/ChatShow.js';

////////////////////////////
////// THIS COMPONENT //////
////////////////////////////
export default function ChatShowBackground() {

  return (
    <TheChatShowFrame>

      <ChatShowBg
        id='chat_show_bgRef' 
      />

      <ChatShowFloor
        id='chat_show_floorRef' 
      />

      <ChatShowSofaBg
        id='chat_show_sofaBgRef' 
      />

    </TheChatShowFrame>
  )
}