//###############################################
//############### NEST BACKGROUND ###############
//###############################################

/////////////////////////////////////
////// NODE & NPM DEPENDENCIES //////
/////////////////////////////////////
import React from 'react'; 

///////////////////////////////
////// STYLED COMPONENTS //////
///////////////////////////////
import {
  TheNestFrame,
  NestBG,
  NestSofa,
  NestPhoto1,
  NestPhoto2,
  NestPhoto3,
  NestPhoto4,
  NestPhoto5,
  NestPlant1,
  NestPlant2,
  NestPlant3,
  NestPlant4,
  NestTVbg
} from '../../styledComponents/TheNest.js'; 

////////////////////////////
////// THIS COMPONENT //////
////////////////////////////
export default function NestBackground() { 

  const photos = {
    photoImage1: {
      current_v: 0,
      url: process.env.REACT_APP_PUBLIC_URL + "/images/the-nest/photo1/0.png"
    },
    photoImage2: {
      current_v: 0,
      url: process.env.REACT_APP_PUBLIC_URL + "/images/the-nest/photo2/0.png"
    },
    photoImage3: {
      current_v: 0,
      url: process.env.REACT_APP_PUBLIC_URL + "/images/the-nest/photo3/0.png"
    },
    photoImage4: {
      current_v: 0,
      url: process.env.REACT_APP_PUBLIC_URL + "/images/the-nest/photo4/0.png"
    },
    photoImage5: {
      current_v: 0,
      url: process.env.REACT_APP_PUBLIC_URL + "/images/the-nest/photo5/0.png"
    }
  };
 
  return (
    <TheNestFrame >

      <NestTVbg
        id='nest-tvBgRef' 
      />

      <NestPlant1
        id='nest-plant1Ref' 
      />
      <NestPlant2
        id='nest-plant2Ref' 
      />
      <NestPlant3
        id='nest-plant3Ref' 
      />
      <NestPlant4
        id='nest-plant4Ref' 
      />

      <NestPhoto1
        id='nest-photo1Ref' 
        $imageurl={photos.photoImage1.url}
      />
      <NestPhoto2
        id='nest-photo2Ref' 
        $imageurl={photos.photoImage2.url}
      />
      <NestPhoto3
        id='nest-photo3Ref' 
        $imageurl={photos.photoImage3.url}
      />
      <NestPhoto4
        id='nest-photo4Ref' 
        $imageurl={photos.photoImage4.url}
      />
      <NestPhoto5
        id='nest-photo5Ref' 
        $imageurl={photos.photoImage5.url}
      />

      <NestSofa
        id='nest-sofaRef' 
      />
 
      <NestBG
        id='nest-bg3' 
        $layer3={true}
      />
      <NestBG
        id='nest-bg2' 
        $layer2={true}
      />
      <NestBG
        id='nest-bg1' 
        $layer1={true}
      />

    </TheNestFrame>
  )
}