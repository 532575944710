//###############################################
//############### HANDLE OBS PAGE ###############
//###############################################
import React from 'react';


export default function HandleDefault() {


  return (
    <>
    </>
  )
}