//###########################################
//########## SMEG TALKS BACKGROUND ##########
//###########################################

/////////////////////////////////////
////// NODE & NPM DEPENDENCIES //////
/////////////////////////////////////
import React from 'react';

///////////////////////////////
////// STYLED COMPONENTS //////
///////////////////////////////
import {
  TheSmegTalkFrame,
  SmegCurtain,
  SmegFloor,
  SmegSign,
  SmegRug
} from '../../styledComponents/SmegTalks.js';

////////////////////////////
////// THIS COMPONENT //////
////////////////////////////
export default function SmegTalksBackground() {
 
  return (
    <TheSmegTalkFrame >

      <SmegCurtain
        id='smeg_talk_smegCurtainRef' 
      />

      <SmegFloor
        id='smeg_talk_smegFloorRef' 
      />

      <SmegSign
        id='smeg_talk_smegSignRef' 
      />

      <SmegRug
        id='smeg_talk_smegRugRef' 
      />

    </TheSmegTalkFrame>
  )
}