//######################################
//############ START SCREEN ############
//######################################
import styled from 'styled-components';

/////////////////////////////////
////// STYLED VARS IMPORTS //////
/////////////////////////////////
import { easing } from './vars/scEasing';

//#########################
//### STYLED COMPONENTS ###
//#########################

////////////////////////////
///////// CANVASES /////////
////////////////////////////
export const TheStartForeground = styled.div`
  display: block;
  width: 3840px;
  height: 2160px;
  position: absolute;
  top:0;
  left:0;
  z-index: 0;

  &.hiding{
    opacity: 0;
  }
`;

////////////////////////////
//////// FOREGROUND ////////
////////////////////////////
export const StartVideoContainer = styled.div`
  display: none;
  width: 100%;
  height: 100%;  
  position: absolute;
  top:0;
  left:0;
  z-index: 51;   
  opacity: 0;
  background:black;

  &.active{
    opacity: 1;
    transition: all 500ms ${easing.easeOutCubic};
    transition-delay:100ms;
    will-change: opacity;
  }

  .start-video{
    display: block;
    width:100%;
    height:100%;
    object-fit: cover;
  } 
`;