//######################################
//######## DO FOREGROUND UPDATE  #######
//######################################

///////////////////////////////
////// IN-LINE FUNCTIONS //////
///////////////////////////////
async function addActive(id) {
  const element = document.getElementById(id);

  if (element) {
    element.classList.add('prep');
  }

  setTimeout(() => {
    if (element) {
      element.classList.add('active');
    }
  }, 75);
}

async function removeActive(id) {
  const element = document.getElementById(id);
  if (element) {
    element.classList.remove('active');
    element.classList.remove('prep');
  }
}

///////////////////////////
////// THIS FUNCTION //////
///////////////////////////
const doForegroundUpdate = async (data) => {

  // START
  if (data.start.active) {  
    addActive('start-videoRef');
  } else { 
    removeActive('start-videoRef');
  }

  // PROJECTOR ROOM
  if (data.projector_room.active) {
    addActive('pr-tableRef'); 
  } else {
    removeActive('pr-tableRef'); 
  }

  // NEST ELEMENTS
  if (data.nest.active) {
    addActive('nest-tvRef');
    addActive('nest-deskRef');
    addActive('nest-plant5Ref');
  } else {
    removeActive('nest-tvRef');
    removeActive('nest-deskRef');
    removeActive('nest-plant5Ref');
  }

  // MEME ROOM
  if (data.meme_review.active) {
    addActive('meme_room_memeRoomLeftChairRef');
    addActive('meme_room_memeRoomRightChairRef'); 
  } else {
    removeActive('meme_room_memeRoomLeftChairRef');
    removeActive('meme_room_memeRoomRightChairRef'); 
  }

  // COMEDY CLUB
  if (data.comedy_club.active) {   
    addActive('comedy_club_micRef'); 
    addActive('comedy_club_speaker1Ref'); 
    addActive('comedy_club_speaker2Ref'); 
    addActive('comedy_club_speaker3Ref'); 
    addActive('comedy_club_speaker4Ref'); 
  } else {       
    removeActive('comedy_club_micRef'); 
    removeActive('comedy_club_speaker1Ref'); 
    removeActive('comedy_club_speaker2Ref'); 
    removeActive('comedy_club_speaker3Ref'); 
    removeActive('comedy_club_speaker4Ref'); 
  }

  // NEWS ROOM
  if (data.news_room.active) {
    addActive('news_room_deskRef'); 
  } else {
    removeActive('news_room_deskRef'); 
  }

  // SMEG TALKS
  if (data.smeg_talk.active) {
    addActive('smeg_talk_smegProjectorRef');  
  } else {
    removeActive('smeg_talk_smegProjectorRef');  
  }

  // SMEG TALKS
  if (data.gym.active) {
    addActive('gym_gymLeftKitRef'); 
    addActive('gym_gymRightKitRef'); 
  } else {
    removeActive('gym_gymLeftKitRef');  
    removeActive('gym_gymRightKitRef'); 
  }

  // CHAT SHOW
  if (data.chat_show.active) {
    addActive('chat_show_deskRef');  
    addActive('chat_show_sofaRef');   
  } else {
    removeActive('chat_show_deskRef'); 
    removeActive('chat_show_sofaRef');    
  }

  // Quiz SHOW
  if (data.quiz_show.active) {
    addActive('quiz_show_deskRef');
    addActive('quiz_show_bigScreenRef'); 
  } else {
    removeActive('quiz_show_deskRef'); 
    removeActive('quiz_show_bigScreenRef');  
  }


  

};

export default doForegroundUpdate;