//###############################################
//########## PROJECTOR ROOM BACKGROUND ##########
//###############################################

/////////////////////////////////////
////// NODE & NPM DEPENDENCIES //////
/////////////////////////////////////
import React from 'react';

////////////////////////
////// COMPONENTS //////
////////////////////////
import Agenda from './projectorRoom/Agenda';

///////////////////////////////
////// STYLED COMPONENTS //////
///////////////////////////////
import {
  TheProjectorRoomFrame,
  ProjectorRoomBG
} from '../../styledComponents/ProjectorRoom.js';

////////////////////////////
////// THIS COMPONENT //////
////////////////////////////
export default function ProjectorRoomBackground() {

  return (
    <TheProjectorRoomFrame >

      <Agenda />

      <ProjectorRoomBG id='pr-projectorRoomBGRef' />

    </TheProjectorRoomFrame>
  )
}