//###############################################
//########## PROJECTOR ROOM FOREGROUND ##########
//###############################################

/////////////////////////////////////
////// NODE & NPM DEPENDENCIES //////
/////////////////////////////////////
import React from 'react';

///////////////////////////////
////// STYLED COMPONENTS //////
///////////////////////////////
import {
  TheStartForeground,
  StartVideoContainer
} from '../../styledComponents/Start.js';

////////////////////////////
////// THIS COMPONENT //////
////////////////////////////
export default function StartForeground() {

  return (
    <TheStartForeground>

      <StartVideoContainer
        id='start-videoRef'
      >
        <video 
          className="start-video"
          autoPlay loop muted 
        >
          <source src={process.env.REACT_APP_PUBLIC_URL + "/images/Bodach_Intro.mp4"} type="video/mp4" />
        </video>

      </StartVideoContainer>

    </TheStartForeground>
  )
}