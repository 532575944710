//############################
//######## NEWS ROOM  ########
//############################

///////////////////////////////
////// STYLED COMPONENTS //////
///////////////////////////////
import {
  Paragraph,
  OverviewContainer,
} from '../../styledComponents/Overview.js';

////////////////////////////
////// THIS COMPONENT //////
////////////////////////////
export default function NewsRoom() {

  return (
    <OverviewContainer>
      <Paragraph>
        If you are watching this, you are part of the B K W A, Bodach’s Keyboard Warrior Army.
      </Paragraph>
      <Paragraph>
        BKWA. fighting lies, tyranny and corruption, One meme at a time.
      </Paragraph>
      <Paragraph>
        Legacy media is dying my friend, and memes are now the world’s number 3 news source, behind Elon Musk’s X and Chuck Norris movies.
      </Paragraph>
      <Paragraph>
        If this segment isn’t illegal, it will be eventually. Welcome to Knowledgeable Fisticuffs, your sword and shield against globalist scum.
      </Paragraph>
    </OverviewContainer>
  )

}