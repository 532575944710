//#####################################
//######## CURRENT SCENE NAME  ########
//#####################################

///////////////////////////////
////// STYLED COMPONENTS //////
///////////////////////////////
import {
  Header
} from '../../styledComponents/Overview.js';

////////////////////////////
////// THIS COMPONENT //////
////////////////////////////
export default function CurrentSceneName(props) {

  const sceneData = props.sceneData;

  if (sceneData.start.active) return <Header>
    START SCREEN
  </Header>
  if (sceneData.projector_room.active) return <Header>
    PROJECTOR ROOM
  </Header>
  if (sceneData.nest.active) return <Header>
    THE NEST
  </Header>
  if (sceneData.meme_review.active) return <Header>
    MYSTERY MEME MIRROR
  </Header>
  if (sceneData.comedy_club.active) return <Header>
    COMEDY CLUB
  </Header>
  if (sceneData.chat_show.active) return <Header>
    CHAT SHOW
  </Header>
  if (sceneData.quiz_show.active) return <Header>
    QUIZ SHOW
  </Header>
  if (sceneData.news_room.active) return <Header>
    KNOWLEDGEABLE FISTICUFFS 
  </Header>
  if (sceneData.smeg_talk.active) return <Header>
    SMEG TALK
  </Header>
  if (sceneData.gym.active) return <Header>
    THE GYM
  </Header>
  if (sceneData.intermission.active) return <Header>
    INTERMISSION
  </Header>
  if (sceneData.end.active) return <Header>
    STREAM ENDING
  </Header>
  if (sceneData.blank.active) return <Header>
    BLANK SCREEN
  </Header> 

  else return null;
}