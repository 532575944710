//####################################################
//############### QUIZ SHOW FOREGROUND ###############
//####################################################

/////////////////////////////////////
////// NODE & NPM DEPENDENCIES //////
/////////////////////////////////////
import React from 'react';

///////////////////////////////
////// STYLED COMPONENTS //////
///////////////////////////////
import {
  TheQuizShowFrame,
  QuizShowDesk,
  QuizShowBigScreen
} from '../../styledComponents/QuizShow.js';

////////////////////////
////// COMPONENTS //////
////////////////////////
import QuizSlides from '../backgrounds/quizSlides/QuizSlides.js';

////////////////////////////
////// THIS COMPONENT //////
////////////////////////////
export default function ChatShowForeground() {
 
  return (
    <TheQuizShowFrame>

      <QuizShowDesk 
        id='quiz_show_deskRef' 
      />

      <QuizShowBigScreen 
        id='quiz_show_bigScreenRef' 
      />
 
        <QuizSlides /> 

    </TheQuizShowFrame>
  )
}