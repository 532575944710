//###############################################
//###############################################
//#########                             #########
//#########      BODACH SHOW OBS        #########
//#########       VERSION 0.0.1         #########
//#########                             #########
//###############################################
//###############################################

/////////////////////////////////////
////// NODE & NPM DEPENDENCIES //////
/////////////////////////////////////
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

/////////////////////////////
////// HANDLER IMPORTS //////
///////////////////////////// 
import HandleDefault from './handlers/HandleDefault';
import HandleOBSForeground from './handlers/HandleOBSForeground';
import HandleOBSMidGround from './handlers/HandleOBSMidGround';
import HandleOBSBackground from './handlers/HandleOBSBackground';
import HandleOverlays from './handlers/HandleOverlays';
import HandleOverview from './handlers/HandleOverview';

////////////////////////////
////// THIS COMPONENT //////
////////////////////////////
function App() {

  return (
    <Router>
      <Routes>

        <Route path="/"
          element={
            <HandleDefault />
          } />
        <Route path="/overlays"
          element={
            <HandleOverlays />
          } />

        <Route path="/foreground"
          element={
            <HandleOBSForeground />
          } />

        <Route path="/midground"
          element={
            <HandleOBSMidGround />
          } />

        <Route path="/background"
          element={
            <HandleOBSBackground />
          } />

        <Route path="/overview"
          element={
            <HandleOverview />
          } />

      </Routes>
    </Router>
  );

}

export default App;
