//####################################
//############ NONE SCENE ############
//####################################
import styled, { css } from 'styled-components';

/////////////////////////////////
////// STYLED VARS IMPORTS //////
/////////////////////////////////
import { easing } from './vars/scEasing';

//#########################
//### STYLED COMPONENTS ###
//#########################
export const StreamChat = styled.div`
  display: block;
  width: 400px;
  height: 600px;
  //border: 1px solid white;
  position: absolute;
  top:0;
  left:0;
  z-index: 10; 
  transition: opacity 500ms ${easing.easeOutCubic};
  will-change: opacity; 
  opacity: 0;

  ${props =>
    props.$visible &&
    css`
      opacity: 1;
   `};

  ${props =>
    props.$pos1 &&
    css`
    transform: translateX(20px)translateY(460px);
  `};

  ${props =>
    props.$pos2 &&
    css`
    transform: translateX(1505px)translateY(460px);
  `};

  ${props =>
    props.$pos3 &&
    css`
    transform: translateX(1505px)translateY(460px);
  `};

  ${props =>
    props.$pos4 &&
    css`
    transform: translateX(1505px)translateY(460px);
  `};

`;



export const QuizCountdown = styled.div`
  display: block;
  width: 300px;
  height: 250px;
  //border: 1px solid white;
  position: absolute;
  top:20px;
  left:0;
  z-index: 10; 
  opacity: 0;

  .quiz-countdown-bg{
    display: block;
    width: 300px;
    height: 200px;
    position: absolute;
    left:0;
    top:0;
    z-index: 1;
    object-fit: cover;
  }

  .quiz-countdown-timer-label{
    display: block;
    width:100%;
    text-align: center;
    position: absolute;
    left:0;
    top:180px;
    font-size:1rem;
    color:#fff;
    z-index: 20;
    text-shadow: 0px 0px 10px rgba(255,52,234,1);
  }

  .quiz-countdown-timer{
    display: block;
    width:100%;
    text-align: center;
    position: absolute;
    left:0;
    top:202.5px;
    font-size:1.5rem;
    color:#fff;
    z-index: 20;
    text-shadow: 0px 0px 30px rgba(255,52,234,1);
  }

  .quiz-countdown-complete{
    display: block;
    width:100%;
    text-align: center;
    position: absolute;
    left:0;
    top:202.5px;
    font-size:1.5rem;
    color:#fff;
    z-index: 20;
    text-shadow: 0px 0px 20px rgba(255,52,234,1);
  }

  .quiz-countdown-url{
    display: block;
    width:100%;
    text-align: center;
    position: absolute;
    left:0;
    top:120px;
    font-size:1rem;
    color:#fff;
    z-index: 20;
    text-shadow: 0px 0px 10px rgba(255,52,234,1);
  }

  .quiz-countdown-pin{
    display: block;
    width:100%;
    text-align: center;
    position: absolute;
    left:0;
    top:137.5px;
    font-size:1.5rem;
    color:#fff;
    z-index: 20;
    text-shadow: 0px 0px 10px rgba(255,52,234,1);
  }

  ${props =>
    props.$visible &&
    css`
      opacity: 0.75;
      transition: opacity 500ms ${easing.easeOutCubic};
      will-change: opacity;
   `};
`;


export const Sponsors = styled.div`
  display: block;
  width: 1920px;
  height: 1080px;
  //border: 1px solid white;
  position: absolute;
  top:0;
  left:0;    
  z-index: 1000; 

  .sponsor-image{
    display: block;
    position: absolute;
    left:50%;
    bottom:100px;
    transform: translate3d(-50%, 0, 0);
    transition: all 500ms ${easing.easeOutCubic};
    opacity: 0;
    
    &.shadow{ 
      box-shadow: 5px 5px 10px 0px rgba(0,0,0,1);
    }

    &.active{ 
      opacity: 1; 
      will-change: opacity;
    } 
    
  }

`;