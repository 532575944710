//####################################################
//############### NEWS ROOM BACKGROUND ###############
//####################################################

/////////////////////////////////////
////// NODE & NPM DEPENDENCIES //////
/////////////////////////////////////
import React, { useRef } from 'react';

////////////////////////
////// COMPONENTS //////
////////////////////////
import NewsRoomSlides from './newsSlides/NewsRoomSlides.js';

///////////////////////////////
////// STYLED COMPONENTS //////
///////////////////////////////
import {
  TheNewsRoomFrame,
  NewsRoomBackgroundVideo,
  NewsRoomSlidesContainer
} from '../../styledComponents/NewsRoom.js';

////////////////////////////
////// THIS COMPONENT //////
////////////////////////////
export default function NewsRoomBackground() {
 
  const videoRef = useRef(null); 
  const backgroundVideoRef = useRef();
  
  return (
    <TheNewsRoomFrame >

      <NewsRoomSlidesContainer>
        <NewsRoomSlides />
      </NewsRoomSlidesContainer>

      <NewsRoomBackgroundVideo
        id='news_room_NewsRoomBackgroundVideo'
        ref={backgroundVideoRef}
      >
        <video
          ref={videoRef}
          className="news-room-bg-vid"
          autoPlay loop muted
        >
          <source src={process.env.REACT_APP_PUBLIC_URL + "/images/news-room/news_room_bg_3.mp4?t=" + Date.now()} type="video/mp4" />
        </video>
      </NewsRoomBackgroundVideo>

    </TheNewsRoomFrame>
  )
}