//#####################################################
//############### HANDLE OBS BACKGROUND ###############
//#####################################################

/////////////////////////////////////
////// NODE & NPM DEPENDENCIES //////
/////////////////////////////////////
import React, { useEffect } from 'react';
import axios from 'axios';
import { io } from "socket.io-client";

////////////////////////
////// COMPONENTS //////
////////////////////////
import NestBackground from '../components/backgrounds/NestBackground.js';
import ProjectorRoomBackground from '../components/backgrounds/ProjectorRoomBackground.js';
import MemeRoomBackground from '../components/backgrounds/MemeRoomBackground.js';
import ComedyClubBackground from '../components/backgrounds/ComedyClubBackground.js';
import NewsRoomBackground from '../components/backgrounds/NewsRoomBackground.js';
import SmegTalksBackground from '../components/backgrounds/SmegTalksBackground.js';
import GymBackground from '../components/backgrounds/GymBackground.js';
import ChatShowBackground from '../components/backgrounds/ChatShowBackground.js';
import QuizShowBackground from '../components/backgrounds/QuizShowBackground.js';

/////////////////////////////
////// UTILITY IMPORTS //////
/////////////////////////////
import doBackgroundUpdate from '../utils/doBackgroundUpdate';

///////////////////////////////
////// STYLED COMPONENTS //////
///////////////////////////////
import { Canvas } from '../styledApp';

//////////////////////////
////// THIS HANDLER //////
//////////////////////////
export default function HandleOBSBackground(props) {

  const accessKey = process.env.REACT_APP_ACCESS_KEY;
  const webAccessKey = process.env.REACT_APP_WEB_ACCESS_KEY;

  useEffect(() => {
    const fetchData = async () => {
      const url = process.env.REACT_APP_STUDIO_API_URL + "/bodach/get-current-scene?t=" + webAccessKey;
      try {
        const response = await axios.get(url);

        if (typeof response === "object") {
          if (response.data.qry === 1) {
            doBackgroundUpdate(response.data.res.currentData);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_API_URL, {
      query: { accessKey },
      transports: ['websocket'],
      upgrade: false
    });

    socket.on("bodachSceneUpdate", data => {
      doBackgroundUpdate(data);
    });

    return () => socket.disconnect();
  }, []);
  
  return (
    <Canvas>
      <ProjectorRoomBackground />
      <NestBackground />
      <MemeRoomBackground />
      <ComedyClubBackground />
      <NewsRoomBackground />
      <SmegTalksBackground />
      <GymBackground />
      <ChatShowBackground />
      <QuizShowBackground />
    </Canvas>
  )
}