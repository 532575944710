//####################################################
//############### QUIZ SHOW BACKGROUND ###############
//####################################################

/////////////////////////////////////
////// NODE & NPM DEPENDENCIES //////
/////////////////////////////////////
import React from 'react';

///////////////////////////////
////// STYLED COMPONENTS //////
///////////////////////////////
import {
  TheQuizShowFrame,
  QuizShowBg,
  QuizShowFloor,
  QuizShowSign,
  QuizShowBigScreenBg
} from '../../styledComponents/QuizShow.js';

////////////////////////////
////// THIS COMPONENT //////
////////////////////////////
export default function ChatShowBackground() {
 
  return (
    <TheQuizShowFrame>

      <QuizShowBg
        id='quiz_show_bgRef' 
      />

      <QuizShowFloor
        id='quiz_show_floorRef' 
      />

      <QuizShowSign
        id='quiz_show_signRef' 
      />

      <QuizShowBigScreenBg
        id='quiz_show_bigScreenBgRef' 
      />

    </TheQuizShowFrame>
  )
}